import React from 'react'
import CountUp from 'react-countup'

interface IncomeInfoProps {
	title: string
	sales: number
	count: number
}

const IncomeInfo = ({ count, sales, title }: IncomeInfoProps) => {
	return (
		<>
			<span className="whitespace-nowrap">{title}</span>

			<CountUp
				className="font-semibold text-sm whitespace-nowrap "
				useEasing
				useGrouping
				separator=","
				suffix="<small>원</small>"
				duration={2}
				delay={0}
				preserveValue
				end={sales}
			/>

			<CountUp
				className="font-semibold text-sm whitespace-nowrap "
				useGrouping
				useEasing
				separator=","
				suffix="<small>건</small>"
				duration={2}
				delay={0}
				preserveValue
				end={count}
			/>
		</>
	)
}

export default IncomeInfo
