import CardWrapper from 'common/components/CardWrapper'
import CircleLoader from 'components/CircleLoader'
import { useQuery } from 'react-query'
import Metric from 'api/metric'
import CardError from 'common/components/CardError'
import CountUp from 'react-countup'
import { BsFillCaretDownFill, BsFillCaretUpFill, BsDashLg } from 'react-icons/bs'
import { withWidth } from '@material-ui/core'
import { isWidthDown } from '@material-ui/core/withWidth'
import { useNavigate } from 'react-router-dom'

const DailyTotal = ({ placeSeq, width }) => {
	const { data, isLoading, isError, refetch } = useQuery(['dashboard', 'sales', 'oneDay'], () => Metric.getOneDaySales(placeSeq))
	const navigate = useNavigate()
	if (isLoading) {
		return (
			<CardWrapper>
				<CircleLoader />
			</CardWrapper>
		)
	}

	if (isError || !data) {
		return <CardError refetch={refetch} />
	}

	const thisDayIncome = +(data.thisDaySales ?? 0)
	const thisDayUser = data.thisDaySalesCount
	const lastDayIncome = +(data.lastDaySales ?? 0)
	const lastDayUser = data.lastDaySalesCount

	const percentage = thisDayIncome === 0 && lastDayIncome === 0 ? 0 : thisDayIncome / (lastDayIncome * 0.01) - 100
	const userPercentage = thisDayUser === 0 && lastDayUser === 0 ? 0 : thisDayUser - lastDayUser
	const isMobile = isWidthDown('md', width)
	return (
		<CardWrapper
			className={`
				p-4 bg-white shadow-sm border-2 rounded-md flex flex-col duration-300 space-y-3
				`}
		>
			<h1
				className="cursor-pointer w-fit rounded-md hover:underline hover:text-white hover:bg-moa duration-300 font-bold text-xl whitespace-nowrap"
				onClick={() => navigate('/analysis/stats/sales')}
			>
				일별통계
			</h1>
			{isMobile ? (
				<div className="grid space-y-3  h-full">
					<div className="grid grid-cols-[1fr_2fr_1.5fr] items-center">
						<div />
						<span>매출</span>
						<span>구매건수</span>
					</div>
					<div className="grid grid-cols-[1fr_2fr_1.5fr] items-center">
						<span>어제</span>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap "
							useGrouping
							useEasing
							separator=","
							suffix="<small>원</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data?.lastDaySales}
						/>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap"
							useEasing
							useGrouping
							separator=","
							suffix="<small>건</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data.lastDaySalesCount}
						/>
					</div>
					<div className="grid grid-cols-[1fr_2fr_1.5fr] items-center">
						<span>오늘</span>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap "
							useGrouping
							useEasing
							separator=","
							suffix="<small>원</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data?.thisDaySales}
						/>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap"
							useEasing
							useGrouping
							separator=","
							suffix="<small>건</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data.thisDaySalesCount}
						/>
					</div>
					<div className="grid grid-cols-[1fr_2fr_1.5fr] items-center">
						<span>통계</span>
						<div
							className={`${
								percentage > 0 ? 'text-moa' : percentage < 0 ? 'text-red-400' : ''
							} flex font-semibold items-center text-sm space-x-1`}
						>
							{percentage === Infinity ? (
								<>
									<BsDashLg />
								</>
							) : (
								<>
									{percentage > 0 ? <BsFillCaretUpFill /> : percentage < 0 ? <BsFillCaretDownFill /> : <BsDashLg />}
									<CountUp
										useEasing
										useGrouping
										separator=","
										decimals={2}
										suffix="<small>%</small>"
										duration={2}
										delay={0}
										preserveValue
										end={+Math.abs(percentage).toFixed(2)}
									/>
								</>
							)}
						</div>
						<div
							className={`${
								userPercentage > 0 ? 'text-moa' : userPercentage < 0 ? 'text-red-400' : ''
							} flex font-semibold items-center text-sm space-x-1`}
						>
							{userPercentage === Infinity ? (
								<>&#8734;</>
							) : (
								<>
									{userPercentage > 0 ? (
										<BsFillCaretUpFill />
									) : userPercentage < 0 ? (
										<BsFillCaretDownFill />
									) : (
										<BsDashLg />
									)}
									<CountUp
										useEasing
										useGrouping
										separator=","
										suffix="<small>건</small>"
										duration={2}
										delay={0}
										preserveValue
										end={+Math.abs(userPercentage)}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className="grid space-y-3 ">
					<div className="grid grid-cols-4 items-center">
						<div />
						<span>어제</span>
						<span>오늘</span>
						<span>통계</span>
					</div>
					<div className="grid grid-cols-4 items-center">
						<span>매출</span>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap "
							useGrouping
							useEasing
							separator=","
							suffix="<small>원</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data?.lastDaySales}
						/>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap "
							useGrouping
							useEasing
							separator=","
							suffix="<small>원</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data?.thisDaySales}
						/>

						<div
							className={`${
								percentage > 0 ? 'text-moa' : percentage < 0 ? 'text-red-400' : ''
							} flex font-semibold items-center text-sm space-x-1`}
						>
							{percentage === Infinity ? (
								<>&#8734;</>
							) : (
								<>
									{percentage > 0 ? <BsFillCaretUpFill /> : percentage < 0 ? <BsFillCaretDownFill /> : <BsDashLg />}
									<CountUp
										useEasing
										useGrouping
										separator=","
										decimals={2}
										suffix="<small>%</small>"
										duration={2}
										delay={0}
										preserveValue
										end={+Math.abs(percentage).toFixed(2)}
									/>
								</>
							)}
						</div>
					</div>
					<div className="grid grid-cols-4 items-center">
						<span>구매건수</span>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap"
							useEasing
							useGrouping
							separator=","
							suffix="<small>건</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data.lastDaySalesCount}
						/>
						<CountUp
							className="font-semibold text-sm whitespace-nowrap"
							useEasing
							useGrouping
							separator=","
							suffix="<small>건</small>"
							duration={2}
							delay={0}
							preserveValue
							end={data.thisDaySalesCount}
						/>
						<div
							className={`${
								userPercentage > 0 ? 'text-moa' : userPercentage < 0 ? 'text-red-400' : ''
							} flex font-semibold items-center text-sm space-x-1`}
						>
							{userPercentage === Infinity ? (
								<>&#8734;</>
							) : (
								<>
									{userPercentage > 0 ? (
										<BsFillCaretUpFill />
									) : userPercentage < 0 ? (
										<BsFillCaretDownFill />
									) : (
										<BsDashLg />
									)}
									<CountUp
										useEasing
										useGrouping
										separator=","
										suffix="<small>건</small>"
										duration={2}
										delay={0}
										preserveValue
										end={+Math.abs(userPercentage)}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</CardWrapper>
	)
}

export default withWidth()(DailyTotal)
