import adminApi from 'api/axios'
import { BusinessType, PlaceListType, PlaceType } from 'types/Place'

async function getPlaceInfo(params: PlaceListType) {
	return adminApi.get('/place/manager', { params })
}

async function postPlace(data: PlaceType) {
	return adminApi.post('/place', { data })
}

async function getPlaceDetailInfo(seq: number) {
	return adminApi.get(`/place/${seq}`)
}

async function postPlaceDetail(seq: number, body: BusinessType) {
	return adminApi.post(`/place/${seq}`, body)
}

async function deletePlace(seq: number) {
	return adminApi.delete(`/place/${seq}`)
}

async function getPlaceConnectInfo(seq: number) {
	return adminApi.get(`/place/${seq}/connect`)
}

const Place = {
	getPlaceInfo,
	getPlaceDetailInfo,
	postPlaceDetail,
	deletePlace,
	postPlace,
	getPlaceConnectInfo
}

export default Place
