import CardWrapper from 'common/components/CardWrapper'
import CircleLoader from 'components/CircleLoader'
import CardError from 'common/components/CardError'
import SeatIcon from '@material-ui/icons/Portrait'
import withNavigation from 'common/withComponent/withNavigation'
import { NavigateFunction } from 'react-router-dom'
import { Fragment } from 'react'
import { useQuery } from 'react-query'
import Metric from 'api/metric'
interface DashboardTotalProps {
	placeSeq: number
	refetch: () => void
	labels?: string[]
	navigate: NavigateFunction
}

const DeskTotal = ({ placeSeq, navigate, labels = ['자유석', '지정석', '충전권', '실시간', '1회권'] }: DashboardTotalProps) => {
	const { isLoading, isError, refetch, data } = useQuery(['dashboard', 'total', 'desk', 'count'], () => Metric.getDeskCount(placeSeq))
	if (isLoading) {
		return (
			<CardWrapper>
				<CircleLoader />
			</CardWrapper>
		)
	}

	if (isError || !data) {
		return <CardError refetch={refetch} />
	}
	const { result } = data
	const usedData = [
		{ count: result.freeDeskCount, total: result.freeDeskTotalCount },
		{ count: result.dayDeskCount },
		{ count: result.charDeskCount, total: result.charDeskTotalCount },
		{ count: result.realDeskCount },
		{ count: result.timeDeskCount }
	]
	return (
		<CardWrapper onClick={() => navigate('/seat')} className="flex flex-col space-y-3 duration-300 cursor-pointer">
			<h1 className="font-bold text-xl">좌석</h1>
			<aside className="grid grid-cols-[1.5fr_1fr] h-full font-semibold items-center duration-300 text-sm">
				{data && (
					<>
						<span className="whitespace-nowrap flex items-center">
							<SeatIcon />
							사용중
						</span>
						<span>
							{data.result?.useDeskCount} / {data.result?.deskCount}
						</span>
						{usedData.map(
							(cnt, idx) =>
								cnt.count !== undefined && (
									<Fragment key={`${cnt}&${idx}`}>
										<span className="whitespace-nowrap flex items-center">
											<SeatIcon />
											{labels[idx]}
										</span>
										<span>
											{cnt.count}
											{cnt.total !== undefined && ` / ${cnt.total}`}
										</span>
									</Fragment>
								)
						)}
					</>
				)}
			</aside>
		</CardWrapper>
	)
}

export default withNavigation(DeskTotal)
