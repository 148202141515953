import { Drawer, withWidth } from '@material-ui/core'
import {
	franchise_loginMenuItems,
	franchise_menuItems,
	normal_menuItems,
	staff_menuItems,
	super_loginMenuItems,
	super_menuItems
} from 'config'
import { useState } from 'react'
import { CgChevronRight } from 'react-icons/cg'
import { useQuery } from 'react-query'
import { useAppDispatch, useAppSelector } from 'reducers'
import { getNavList } from './utils'
import { IoExitOutline } from 'react-icons/io5'
import { isWidthDown } from '@material-ui/core/withWidth'
import { ArrowDropDown } from '@material-ui/icons'
import adminApi from 'api/axios'

import ChangePlaceMenu from './components/ChangePlaceMenu'
import ConstructNav from './components/ConstructNav'
import Common from 'api/common'
import withNavigation from 'common/withComponent/withNavigation'
import { setIsDark, setNavOpen } from 'reducers/common.reducer'
import MoonIcon from '../content-toolbar/components/MoonIcon'
import SunIcon from '../content-toolbar/components/SunIcon'

const SideNavLayout = ({ width }) => {
	const isMobile = isWidthDown('sm', width)
	const [hover, setHover] = useState(true)
	const [superEl, setSuperel] = useState()
	const isDark = useAppSelector((state) => state.common.isDark)
	const navItems =
		Number(window.sessionStorage.getItem('manager_permission')) === 9 //슈퍼관리자
			? localStorage.getItem('manager_place') //공간 접속 여부
				? super_menuItems
				: super_loginMenuItems
			: Number(window.sessionStorage.getItem('manager_permission')) === 7 //프차관리자
			? localStorage.getItem('manager_place') //공간 접속 여부
				? franchise_menuItems
				: franchise_loginMenuItems
			: Number(window.sessionStorage.getItem('manager_permission')) === 2 //일반관리자(사장)
			? normal_menuItems
			: staff_menuItems

	const toggle = useAppSelector((state) => state.common.isNavOpen)
	const dispatch = useAppDispatch()
	const { data } = useQuery(['common', 'nav', 'all'], getNavList, {
		staleTime: Infinity,
		cacheTime: Infinity
	})

	const handleSuperClick = (event) => {
		setSuperel(event.currentTarget)
	}

	const handleSuperClose = () => {
		setSuperel(undefined)
	}

	const handlePlaceChange = (event, seq, key, name) => {
		adminApi
			.get('/place/' + seq + '/connect')
			.then((res) => {
				if (res.data.result === 'success') {
					localStorage.setItem('manager_place', JSON.stringify({ key: key, seq: seq, name: name }))
					localStorage.setItem('access_token', res.data.access_token)
					adminApi.defaults.headers.common['access_token'] = res.data.access_token
					if (window.location.pathname === '/seatsetting' || window.location.pathname === '/sprodsetting') {
						window.location.href = '/seat'
					} else if (window.location.pathname === '/lockersetting' || window.location.pathname === '/lprodsetting') {
						window.location.href = '/locker'
					} else {
						window.location.reload()
					}
				}
			})
			.catch((error) => console.error(error.response))
	}

	const handleLogout = async () => {
		const res = await Common.logout()
		if (res) {
			// navigate('/login')
			window.location.pathname = '/login'
		}
	}

	return !isMobile ? (
		<div className="max-h-[calc(100vh-52px)] bg-white dark:bg-moaDark relative">
			<div
				className={`h-full py-4 min-w-[55px] border-r-[1px] dark:border-r-[rgba(255,255,255,0.1)] ${
					hover ? 'w-[240px] min-w-[240px]' : 'w-[55px]'
				} relative flex flex-col items-center  bg-white dark:bg-moaDark z-50 duration-300`}
			>
				<div
					className="p-1 cursor-pointer"
					onClick={() => {
						dispatch(setIsDark(!isDark))
					}}
				>
					{isDark ? <MoonIcon className="text-yellow-300" /> : <SunIcon className="fill-[#ff9307]" />}
				</div>
				{Number(window.sessionStorage.getItem('manager_permission')) >= 7 && (
					<ArrowDropDown
						onClick={
							Number(window.sessionStorage.getItem('manager_permission')) >= 7
								? handleSuperClick
								: () => {
										return null
								  }
						}
						className="absolute right-4 top-0 cursor-pointer dark:fill-moaWhite"
					/>
				)}

				<div
					className="absolute flex justify-center items-center -right-2 top-4 bg-white w-4 h-4 ring-2 hover:ring-offset-1 rounded-full cursor-pointer z-50 hover:bg-moa duration-300"
					onClick={() => {
						setHover(!hover)
					}}
				>
					<CgChevronRight className={`${hover && 'rotate-180'} duration-300`} />
				</div>

				<ChangePlaceMenu el={superEl} data={data} onClick={handlePlaceChange} onClose={handleSuperClose} />

				<ul className="w-full overflow-y-scroll  p-2 py-4 pb-2 scrollbar-hide flex flex-col space-y-2" id="nav-container">
					<ConstructNav items={navItems} hover={hover} />
				</ul>
				{hover && (
					<button
						onClick={handleLogout}
						className="flex space-x-4 font-semibold items-center justify-center w-fit bottom-4 p-1 px-8 rounded-md text-xs hover:bg-moa duration-300 dark:text-moaWhite whitespace-nowrap hover:text-moaWhite"
					>
						<IoExitOutline className="w-4 h-4" />
						<span>로그아웃</span>
					</button>
				)}
			</div>
		</div>
	) : (
		<Drawer
			variant="temporary"
			open={toggle}
			onClose={() => dispatch(setNavOpen())}
			anchor="left"
			ModalProps={{
				keepMounted: true
			}}
		>
			<div className="h-full pb-4 w-[160px] min-w-[160px] relative flex flex-col items-center  bg-white dark:bg-moaDark z-50 duration-300">
				<div
					className="p-1 py-3 cursor-pointer"
					onClick={() => {
						dispatch(setIsDark(!isDark))
					}}
				>
					{isDark ? <MoonIcon className="text-yellow-300" /> : <SunIcon className="fill-[#ff9307]" />}
				</div>
				{Number(window.sessionStorage.getItem('manager_permission')) >= 7 && (
					<ArrowDropDown
						onClick={
							Number(window.sessionStorage.getItem('manager_permission')) >= 7
								? handleSuperClick
								: () => {
										return null
								  }
						}
						className="absolute right-0 top-0 dark:fill-moaWhite"
					/>
				)}

				<ChangePlaceMenu el={superEl} data={data} onClick={handlePlaceChange} onClose={handleSuperClose} />
				<ul className="w-full overflow-y-scroll p-2 py-4 pb-2 scrollbar-hide flex flex-col space-y-2" id="nav-container">
					<ConstructNav items={navItems} hover={toggle} />
				</ul>
				{hover && (
					<button
						onClick={handleLogout}
						className="flex space-x-4 font-semibold text-xs items-center justify-center w-fit bottom-4 p-1 mx-2 rounded-md hover:bg-moa duration-300 dark:text-moaWhite whitespace-nowrap hover:text-moaWhite"
					>
						<IoExitOutline className="w-4 h-4" />
						<span>로그아웃</span>
					</button>
				)}
			</div>
		</Drawer>
	)
}

export default withWidth()(withNavigation(SideNavLayout))
