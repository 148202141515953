import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import App from './App'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import WebFont from 'webfontloader'
import registerServiceWorker from './registerServiceWorker'
import CONSTANTS from 'common/constant'
import { store } from 'store'
import React from 'react'
WebFont.load({
	google: {
		families: ['Barlow:300,400,400i,500,600,700']
	}
})

const encodedLists = ['manager_id', 'manager_seq', 'manager_permission']
const _getItem = window.sessionStorage.getItem
const isDev = process.env.NODE_ENV === 'development' // 비활성화

window.sessionStorage.getItem = function (key) {
	if (encodedLists.includes(key) && isDev) {
		try {
			const encoded = _getItem.call(this, key)
			const decoded = atob(encoded!)
			return decoded
		} catch (err) {
			return _getItem.call(this, key)
		}
	}
	return _getItem.call(this, key)
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 5,
			keepPreviousData: true,
			staleTime: 30000,
			cacheTime: Infinity,
			enabled: true
			// refetchInterval: (_, query) => (query.initialState.error ? 0 : CONSTANTS.REFETCH_TIME)
		}
	}
})

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<Router>
				<App />
			</Router>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</Provider>
)
registerServiceWorker()
