import { alertMessage } from 'common/utils'
import adminApi from './axios'

async function logout() {
	try {
		const res = await adminApi.get('manager/logout', { headers: { 'Content-type': 'application/json' } })
		if (res.data.result === 'success') {
			localStorage.clear()
			sessionStorage.clear()
		}
		return true
	} catch (err) {
		alertMessage({ title: '알림', message: '로그아웃에 실패했습니다.', type: 'warning' })
		return false
	}
}
const Common = { logout }

export default Common
