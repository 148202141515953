import CardWrapper from 'common/components/CardWrapper'
import CircleLoader from 'components/CircleLoader'
import CardError from 'common/components/CardError'
import StudyRoomIcon from '@material-ui/icons/RecentActors'
import withNavigation from 'common/withComponent/withNavigation'
import { NavigateFunction } from 'react-router-dom'
import { useQuery } from 'react-query'
import Metric from 'api/metric'

interface RoomTotal {
	roomCnt: (number | undefined)[]
	reservedRoomCnt: number | undefined
	monthRoomCnt: number | undefined
}
interface DashboardTotalProps {
	placeSeq: number
	navigate: NavigateFunction
}

const RoomTotal = ({ navigate, placeSeq }: DashboardTotalProps) => {
	const { data, isError, isLoading, refetch } = useQuery(['dashboard', 'total', 'room', 'count'], () => Metric.getRoomCount(placeSeq))
	if (isLoading) {
		return (
			<CardWrapper>
				<CircleLoader />
			</CardWrapper>
		)
	}

	if (isError || !data) {
		return <CardError refetch={refetch} />
	}

	return (
		<CardWrapper className="flex flex-col space-y-3 duration-300 cursor-pointer" onClick={() => navigate('/room')}>
			<h1 className="font-bold text-xl">스터디룸</h1>
			<aside className="grid grid-cols-[1.5fr_1fr] h-full font-semibold items-center text-sm">
				{data.result.useRoomCount !== undefined && (
					<>
						<span className="whitespace-nowrap space-x-1 flex items-center">
							<StudyRoomIcon fill="white" />
							<span>사용중</span>
						</span>
						<span>
							{data.result.useRoomCount} / {data.result.roomCount}
						</span>
					</>
				)}
				{data.result.reservedRoomCnt !== undefined && (
					<>
						<span className="whitespace-nowrap space-x-1 flex items-center">
							<StudyRoomIcon />
							<span>예약</span>
						</span>
						<span>{data.result.reservedRoomCnt}건</span>
					</>
				)}
				{data.result.monthRoomCnt !== undefined && (
					<>
						<span className="whitespace-nowrap space-x-1 flex items-center">
							<StudyRoomIcon />
							<span>이번달</span>
						</span>
						<span>{data.result.monthRoomCnt}건</span>
					</>
				)}
			</aside>
		</CardWrapper>
	)
}

export default withNavigation(RoomTotal)
