import { createBrowserHistory } from 'history'
import moment from 'moment'
import { NotificationTitleMessage, NOTIFICATION_TYPE, Store } from 'react-notifications-component'
import { RoomUsage } from 'types/Room'
import axios from '../api/axios'
import { ImageUploadResponse } from './../types/CommonType'
import imageCompression from 'browser-image-compression';
interface iAlert {
	title: NotificationTitleMessage
	message: NotificationTitleMessage
	type: NOTIFICATION_TYPE
	duration?: number
}

export const history = createBrowserHistory()

export const alertMessage = ({ title, message, type, duration = 1500 }: iAlert) => {
	Store.addNotification({
		title: title,
		message: message,
		type: type,
		insert: 'top',
		container: 'top-center',
		animationIn: ['animated', 'fadeIn'],
		animationOut: ['animated', 'fadeOut'],
		dismiss: { duration: duration }
	})
}

export async function postImageUpload(file: File, placeKey: string) {
	const formData = new FormData()
	const options = {
		maxSizeMB: 2, // 허용하는 최대 사이즈 지정
		maxWidthOrHeight: 400, // 허용하는 최대 width, height 값 지정
		useWebWorker: true // webworker 사용 여부
	}
	const compressedFile = await imageCompression(file, options);

	formData.append('file', compressedFile)
	formData.append('placeKey', placeKey)
	return axios.post<ImageUploadResponse>('/image/upload', formData)
}

export function YYYYMMDD(data: Date | string) {
	const result = moment(data).format('YYYY-MM-DD')
	if (result === 'Invalid date') {
		return '-'
	}
	return result
}

export const throttle = (func, ms) => {
	let throttled = false
	return (...args) => {
		if (!throttled) {
			throttled = true
			setTimeout(() => {
				func(...args)
				throttled = false
			}, ms)
		}
	}
}

export function textCutTo20(text: string) {
	return text.length >= 20 ? text.slice(0, 20) + ' ...' : text
}

export function formatDiffNowTime(time: string) {
	const editTime = moment.duration(moment().diff(moment(time)))
	let text = ''
	const year = editTime.get('y')
	const month = editTime.get('M')
	const day = editTime.get('d')
	const hour = editTime.get('h')
	const minute = editTime.get('m')
	const seconds = editTime.get('s')
	if (year) {
		text = `${year}년 전`
	} else if (month) {
		text = `${month}개월 전`
	} else if (day) {
		text = `${day}일 전`
	} else if (hour) {
		text = `${hour}시간 전`
	} else if (minute) {
		text = `${minute}분 전`
	} else {
		text = `${seconds}초 전`
	}
	return text
}
export function delay<T>(time: number, returnValue: T | boolean = true) {
	return new Promise((resolve) => {
		setTimeout(() => resolve(returnValue), time)
	})
}

export function getTotalUsageMinutes(data?: RoomUsage[]) {
	if (!data || !data.length) {
		return
	}
	const time = data
		.map((reserved) => {
			const start = moment(reserved.startDT)
			const end = moment(reserved.endDT)
			const result = end.diff(start, 'minutes')
			// console.log(result)
			return result
		})
		.reduce((acc, cur) => {
			return acc + cur
		})
	return time
}

export function setMobileInterval(callback: () => void, delay: number) {
	const checkInterval = delay / 10
	let lastFired = new Date()
	const fire = () => {
		const now = new Date()
		if (now.getTime() - lastFired.getTime() > delay) {
			lastFired = new Date()
			callback()
		}
	}

	const interval = setInterval(fire, checkInterval)
	return interval
}
