import { ChartOptions } from 'chart.js'
import CONSTANTS from 'common/constant'
import { Bar } from 'react-chartjs-2'

interface StackedChartProps {
	data: any
	labels?: string[]
	bgs?: string[]
	percent?: number
	options?: ChartOptions<'bar'>
}

const StackedBar = ({ data, labels, options }: StackedChartProps) => {
	const parsed = {
		labels: labels ?? [],
		datasets: data
	}
	return (
		<div className="">
			<Bar
				data={parsed}
				options={{
					...options,
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						x: {
							stacked: true
						},
						y: {
							stacked: true
						}
					},
					plugins: {
						tooltip: {
							position: 'average',
							cornerRadius: 10,
							caretPadding: 10,
							caretSize: 10,
							callbacks: {
								label: (chart) => {
									return `${chart.dataset.label}: ${chart.formattedValue}%`
								}
							}
							// rtl: true,
						},
						datalabels: {
							formatter: (value, ctx) => {
								return value + '%'
							},
							color: CONSTANTS.CHART_COLORS.LABEL_TEXT,
							clamp: true,
							font: {
								weight: 'bold'
							}
						},
						legend: {
							display: false,
							// align: 'end',
							fullSize: true,
							labels: {
								color: 'black',
								boxWidth: 100
							},
							position: 'right'
						}
					}
				}}
				// plugins={[
				// 	{
				// 		id: '7',
				// 		afterDraw: function (chart) {
				// 			const isUsage = Number(chart.data.datasets[0].data[0])
				// 			if (!isUsage) {
				// 				const ctx = chart.ctx
				// 				const width = chart.width
				// 				const height = chart.height
				// 				ctx.textAlign = 'center'
				// 				ctx.textBaseline = 'middle'
				// 				ctx.font = '42px 600'
				// 				ctx.fillText('예약내역이 없습니다.', width / 2, height / 2)
				// 				ctx.restore()
				// 			}
				// 		}
				// 	}
				// ]}
			/>
		</div>
	)
}

export default StackedBar
