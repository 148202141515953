import adminApi from 'api/axios'
import moment from 'moment'
import * as RoomTypes from 'types/Room'

async function getRoomSales(
	placeSeq: number,
	year: number = moment().year(),
	month: number = moment().month() + 1
): Promise<RoomTypes.RoomSalesReponse> {
	const res = await adminApi.get(`/room/${placeSeq}/usage/${year}/${month}`)
	return res.data
}

const RoomApi = { getRoomSales }

export default RoomApi
