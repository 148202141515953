import { TextField } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'
import { InputTitle, InputWrapper } from '../EditInfo.styled'

function TextInput({ title, admin = false, value, control, name, type = 'text', disabled, style, placeholder='' }) {
	return (
		<InputWrapper style={style}>
			<InputTitle admin={admin} className="col-md-2">
				{title}
			</InputTitle>
			{control ? (
				<Controller
					name={name}
					control={control}
					defaultValue={value}
					render={({ field: { onChange, value } }) => (
						<TextField
							className="col-md-9 editinfo_tf_2"
							margin="normal"
							InputProps={{ classes: { input: 'dark:text-moaWhite' } }}
							type={type}
							onChange={onChange}
							value={value}
							disabled={disabled}
							placeholder={placeholder}
						/>
					)}
				/>
			) : (
				<TextField
					InputProps={{ classes: { input: 'dark:text-moaWhite opacity-50' } }}
					className="col-md-9 editinfo_tf_2"
					margin="normal"
					type={type}
					value={value}
					disabled={disabled}
					placeholder={placeholder}
				/>
			)}
		</InputWrapper>
	)
}

export default TextInput
