import Metric from 'api/metric'
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Tooltip,
	LineController,
	registerables
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Chart from 'common/components/Chart'
import { useQuery } from 'react-query'
import RoomApi from 'api/room'
import DeskUsageChart from 'common/components/DeskUsageChart'
import RoomUsageChart from 'common/components/RoomUsageChart'
import { alertMessage } from 'common/utils'
import { useEffect } from 'react'
import SalesTotal from './components/MonthTotal'
import TableWidgetDeskUsage from './components/table-widget/TableWidgetDeskusage'
import CardWrapper, { CardVariants } from 'common/components/CardWrapper'
import withNavigation from 'common/withComponent/withNavigation'
import LineIncome from './components/LineIncome'
import { motion } from 'framer-motion'
import DailyTotal from './components/DailyTotal'
import SimpleTotal from './components/DailyTotal/SimpleTotal'
import DeskTotal from './components/DeskTotal'
import RoomTotal from './components/RoomTotal'
import LockerTotal from './components/LockerTotal'
import withWidth from '@material-ui/core/withWidth'
import { isMobile } from 'react-device-detect'
import TableWidgetSales from './components/table-widget/TableWidgetSales'
ChartJS.register(
	CategoryScale,
	LineController,
	Tooltip,
	Legend,
	LinearScale,
	PointElement,
	LineElement,
	ArcElement,
	BarElement,
	ChartDataLabels,
	...registerables
)

function Dashboard({ navigate, width }) {
	const { seq } = JSON.parse(localStorage.getItem('manager_place') ?? '{}')
	const {
		data: desks,
		isLoading: desksLoading,
		isError: desksError,
		refetch: desksRefetch
	} = useQuery(['dashboard', 'desks'], () => Metric.getDesks(seq))
	const {
		data: rooms,
		isLoading: roomsLoading,
		isError: roomsError,
		refetch: roomsRefetch
	} = useQuery(['dashboard', 'rooms'], () => Metric.getRooms(seq))
	const {
		data: lockers,
		isLoading: lockersLoading,
		isError: lockersError,
		refetch: lockersRefetch
	} = useQuery(['dashboard', 'lockers'], () => Metric.getLockers(seq))
	const {
		data: roomSales,
		isLoading: roomSalesLoading,
		isError: roomSalesError,
		refetch: roomSalesRefetch
	} = useQuery(['dashboard', 'roomSales'], () => RoomApi.getRoomSales(seq))
	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		animation: {
			duration: 2000
		},
		plugins: {
			legend: {
				position: 'bottom',
				display: false
			}
		},
		cutout: '70%',
		offset: 10,
		layout: {
			autoPadding: true,
			padding: 10
		}
	}

	useEffect(() => {
		if (!seq) {
				alertMessage({ title: '알림', message: '세션이 만료되었습니다. 다시 로그인해주세요.', type: 'danger', duration: 4000 })
				setTimeout(() => navigate('/login'), 4000)
		}
	}, [seq])

	// const isMobile = isWidthDown('xs', width)

	return (
		<div className="p-3 flex gap-3 flex-wrap dark:text-moaWhite">
			{isMobile && seq && (
				<div className="gap-3 w-full grid grid-cols-2 md:grid-cols-2 2md:grid-cols-4">
					<DeskTotal placeSeq={seq} />
					<section className="grid gap-3">
						<RoomTotal placeSeq={seq} />
						<LockerTotal placeSeq={seq} />
					</section>
				</div>
			)}
			<div className="gap-3 w-full grid grid-cols-1 md:grid-cols-3  lg:grid-cols-[1.2fr_1fr_1fr]">
				<SimpleTotal placeSeq={seq} />
				<DailyTotal placeSeq={seq} />
				<SalesTotal placeSeq={seq} />
			</div>

			<div className="flex flex-wrap md:flex-nowrap gap-3 grow max-w-full">
				<div className="w-full flex flex-col flex-wrap gap-3 justify-between md:w-fit">
					<Chart
						data={desks}
						error={desksError}
						loading={desksLoading}
						title="좌석현황"
						link="/seat"
						options={options}
						refetch={desksRefetch}
					/>
					<Chart
						data={rooms}
						error={roomsError}
						loading={roomsLoading}
						title="스터디룸현황"
						link="/room"
						options={options}
						refetch={roomsRefetch}
					/>
					<Chart
						data={lockers}
						loading={lockersLoading}
						title="락커현황"
						link="/locker"
						options={options}
						error={lockersError}
						refetch={lockersRefetch}
					/>
				</div>
				<div className="grid gap-3 w-full grid-cols-1 2md:grid-cols-2  grid-rows-[min-content,1fr]">
					<div className="col-span-full grid gap-3  grid-cols-1 2md:grid-cols-[min-content,1fr]">
						<DeskUsageChart placeSeq={seq} title="좌석점유율" link="/seat" options={options} />
						{roomSales?.rooms.length ? (
							<CardWrapper className="mb:min-w-full flex-col min-h-[240px] max-h-[266px] overflow-x-scroll">
								<span
									onClick={() => navigate('/room')}
									className="w-fit cursor-pointer rounded-md hover:underline hover:text-white hover:bg-moa duration-300 font-bold text-xl whitespace-nowrap"
								>
									스터디룸 점유율
								</span>
								<motion.div className="flex gap-4" variants={CardVariants} initial="initial" animate="start" exit="initial">
									{roomSales.rooms.map((room) => (
										<RoomUsageChart key={room.key} room={room} />
									))}
								</motion.div>
							</CardWrapper>
						) : null}
					</div>
					<LineIncome placeSeq={seq} />
				</div>
			</div>

			<CardWrapper className="p-4 bg-white shadow-sm border-2 rounded-md space-x-6 w-full">
				<TableWidgetDeskUsage />
			</CardWrapper>
			<CardWrapper className="p-4 bg-white shadow-sm border-2 rounded-md space-x-6 w-full">
				<TableWidgetSales />
			</CardWrapper>
		</div>
	)
}

export default withWidth()(withNavigation(Dashboard))
