import { Variants } from 'framer-motion'
import adminApi from 'api/axios'

export interface NavList {
	isOpen?: boolean
	isService?: boolean
	seq: number
	name: string
	key: string
}

interface getNavReponse {
	list: NavList[]
}

export const slideVariant: Variants = {
	initial: {
		x: -100,
		display: 'none',
		transition: {
			duration: 0.001,
			type: 'tween'
		}
	},
	start: {
		x: 0,
		display: 'block',
		transition: {
			// type: 'tween',
			// duration: 0.1,
			delay: 0.1
		}
	}
}

export const getNavList = async () => {
	return await adminApi
		.get<getNavReponse>('/place/manager/names', {
			headers: { 'Content-type': 'application/json' }
		})
		.then((res) => {
			if (res.status == 200) {
				if (Number(window.sessionStorage.getItem('manager_permission')) === 9) {
					res.data.list &&
						res.data.list.sort((a, b) => {
							if (a.seq == 1 || a.seq == 30 || a.seq == 81) return -1
							else if (b.seq == 1 || b.seq == 30 || b.seq == 81) return 1
							else return 0
						})
					res.data.list &&
						res.data.list.unshift(
							{ key: 'PLV0vIiRKD', name: '테스트 스터디카페', seq: 5 },
							{ key: 'PLM4hppU1L', name: 'TEST 스터디카페', seq: 38 }
						)
				}
				return res.data.list
			}
		})
}
