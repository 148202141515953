import { Component } from 'react'
import LayoutLoader from '../layouts/components/layout-loader/layout-loader.component'

export default function asyncComponent(importComponent) {
	class AsyncComponent extends Component {
		constructor(props) {
			super(props)

			this.state = {
				component: null
			}
		}

		async componentDidMount() {
			const { default: component } = await importComponent()
			this.setState({
				component
			})
		}

		render() {
			const C = this.state.component
			console.log(this.props)

			return C ? <C {...this.props} /> : <LayoutLoader />
		}
	}

	return AsyncComponent
}
