import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'
import axios from '../../api/axios'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import { ReactNotifications, Store } from 'react-notifications-component'

import 'react-notifications-component/dist/theme.css'
import Pagination from 'react-js-pagination'
import scss from './history.module.scss'
import { isMobile } from 'react-device-detect'
import { debounce } from 'utils'


const HistoryManager = () => {
	const [options] = useState({ defaultSortName: 'seq', noDataText: '데이터 없음' })
	const [historyData, setHistoryData] = useState([])
	const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'))
	const [lastDate, setLastDate] = useState(moment().add(1, 'months').date(0).format('YYYY-MM-DD'))
	const [activePage, setActivePage] = useState(1)
	const [listTotal, setListTotal] = useState(0)
	const [sizePerPage, setSizePerPage] = useState(10)
	const [searchValue, setSearchValue] = useState('')
	const [searchData, setSearchData] = useState('')
	const [defaultOrder, setDefaultOrder] = useState('seq')
	const [order, setOrder] = useState('desc')

	const loadData = async () => {
		if (firstDate === '' || firstDate === null || lastDate === '' || lastDate === null) {
			alert('날짜를 선택해주세요')
			return
		}
		try {
			const { data: res } = await axios.get('/history/manager', {
				params: {
					from: firstDate,
					to: lastDate,
					page: activePage,
					perPage: sizePerPage,
					search: searchValue ? searchValue : null,
					orderBy: defaultOrder,
					align: order
				}
			})
			const data = res.list
			const _data = []

			for (let i = 0; i < data.length; i++) {
				const push_data = {
					no: data[i].no,
					seq: data[i].seq,
					placeName: data[i].place ? data[i].place.name : '',
					managerName: data[i].manager ? data[i].manager.username : '',
					memberName: data[i].member ? data[i].member.name || '이름없음' : '',
					memberPhone: data[i].member ? data[i].member.phone : '',
					action: data[i].action,
					memo: data[i].memo,
					wdate: moment(data[i].wdate).format('M/D HH:mm')
				}
				_data.push(push_data)
			}
			setHistoryData(_data)
			setListTotal(res.total)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		loadData()
	}, [activePage, sizePerPage, searchData])

	const changeSizePerPage = (n) => {
		setActivePage(1)
		setSizePerPage(n)
	}

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber)
	}

	//상품선택 => 시간 지정
	const onKeyDown = (event) => {
		debounce(() => {
			if (event.key === 'Enter') {
				setActivePage(1)
				event.preventDefault()
				event.stopPropagation()
				setSearchData(searchValue)
			}
		}, 300)
	}

	const onSearch = async () => {
		setActivePage(1)
		setSearchData(searchValue)
	}

	const dataSort = async (dataField) => {
		if (defaultOrder !== dataField) {
			setDefaultOrder(dataField)
			setOrder('desc')
		} else {
			setDefaultOrder(dataField)
			if (order === 'desc') {
				setOrder('asc')
			} else {
				setOrder('desc')
			}
		}
		loadData()
	}

	const orderCheck = (value) => {
		if (value === defaultOrder) {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq'
						? 'No'
						: value === 'placeName'
						? '공간'
						: value === 'managerName'
						? '관리자'
						: value === 'memberName'
						? '회원명'
						: value === 'memberPhone'
						? '전화번호'
						: value === 'action'
						? '행동'
						: value === 'memo'
						? '내용'
						: value === 'wdate'
						? '발생시간'
						: value}
					<span className={classNames('order', order === 'asc' ? 'dropup' : '')}>
						<span className="caret" style={{ margin: '10px 5px' }}></span>
					</span>
				</div>
			)
		} else {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq'
						? 'No'
						: value === 'placeName'
						? '공간'
						: value === 'managerName'
						? '관리자'
						: value === 'memberName'
						? '회원명'
						: value === 'memberPhone'
						? '전화번호'
						: value === 'action'
						? '행동'
						: value === 'memo'
						? '내용'
						: value === 'wdate'
						? '발생시간'
						: value}
					<span className="order">
						<span className="dropdown">
							<span className="caret" style={{ margin: '10px 0px 10px 5px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
						<span className="dropup">
							<span className="caret" style={{ margin: '10px 0px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
					</span>
				</div>
			)
		}
	}

	return (
		<div className={scss.main} style={{ minWidth: isMobile ? '100%' : null, margin: isMobile ? '5% 0' : null }}>
			<ReactNotifications />

			<div
				className={'row'}
				style={{ height: '60px', verticalAlign: 'middle', padding: '0px 20px', margin: isMobile ? '0' : null }}
			>
				<TextField
					id="date"
					type="date"
					style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
					className={'sales_dateselect'}
					InputLabelProps={{
						shrink: true
					}}
					value={firstDate}
					onChange={(e) => setFirstDate(e.target.value)}
				/>

				<span
					style={{
						width: isMobile ? '20px' : '30px',
						display: 'inline-block',
						textAlign: 'center',
						fontSize: isMobile ? '20px' : '30px',
						fontWeight: '400'
					}}
				>
					~
				</span>

				<TextField
					id="date"
					type="date"
					style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
					className={'sales_dateselect'}
					InputLabelProps={{
						shrink: true
					}}
					value={lastDate}
					onChange={(e) => setLastDate(e.target.value)}
				/>

				<Button variant="outlined" color="default" className={'sales_datebtn btn-dark'} onClick={() => {setActivePage(1); loadData()}}>
					조회
				</Button>
			</div>
			<div>
				<div className="row" style={{ margin: isMobile ? '0' : null }}>
					<div className="col-xs-6 col-sm-6 col-md-6 col-lg-9">
						<div className="btn-group btn-group-sm" role="group">
							<div>
								<div></div>
							</div>
						</div>
					</div>
					<div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
						<div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
							<input
								className="form-control "
								type="text"
								placeholder="회원이름, 없을시 전화번호"
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
								onKeyDown={(e) => { onKeyDown(e)}}
								style={{ zIndex: '0' }}
							></input>
							<span className="input-group-btn">
								<button className="btn btn-default" onClick={() => onSearch()}>
									검색
								</button>
							</span>
						</div>
					</div>
				</div>
				<div style={{ margin: isMobile ? '5%' : null }}>
					<BootstrapTable
						data={historyData}
						options={options}
						// search
						// keyBoardNav
						// pagination
						hover
						className={'study_place_bs'}
						condensed={true}
					>
						<TableHeaderColumn dataField="seq" isKey={true} hidden={true} dataAlign="center">
							Seq
						</TableHeaderColumn>
						<TableHeaderColumn dataField="no" width="60px" dataAlign="center">
							{/* No */}
							{orderCheck('seq')}
						</TableHeaderColumn>
						<TableHeaderColumn dataField="placeName" width="120px" dataAlign="center">
							{/* 유저 */}
							{orderCheck('placeName')}
						</TableHeaderColumn>
						<TableHeaderColumn dataField="managerName" width="80px" dataAlign="center">
							{/* 유저 */}
							{orderCheck('managerName')}
						</TableHeaderColumn>
						<TableHeaderColumn dataField="memberName" width="80px" dataAlign="center">
							{/* 유저 */}
							{orderCheck('memberName')}
						</TableHeaderColumn>
						<TableHeaderColumn
							dataField="memberPhone"
							width="94px"
							dataAlign="center"
							dataFormat={(cell) => <a href={'sms:' + cell}>{cell}</a>}
						>
							{/* 전화번호 */}
							{orderCheck('memberPhone')}
						</TableHeaderColumn>
						<TableHeaderColumn dataField="action" width="110px" dataAlign="center">
							{/* 행동 */}
							{orderCheck('action')}
						</TableHeaderColumn>
						<TableHeaderColumn dataField="memo" width="200px" dataAlign="center">
							{/* 내용 */}
							{orderCheck('memo')}
						</TableHeaderColumn>
						<TableHeaderColumn dataField="wdate" width="100px" dataAlign="center">
							{/* 발생시간 */}
							{orderCheck('wdate')}
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
				<div className="btn-group" style={{ width: '100%', padding: isMobile ? '0 5%' : null }}>
					{[10, 25, 50].map((n, idx) => {
						const isActive = n === sizePerPage ? 'active' : null
						return (
							<button
								key={idx}
								type="button"
								style={{ margin: isMobile ? '0' : '20px 0' }}
								className={`btn ${isActive}`}
								onClick={() => changeSizePerPage(n)}
							>
								{n}
							</button>
						)
					})}
					<div style={{ float: 'right', width: isMobile ? '100%' : null, textAlign: isMobile ? 'right' : null }}>
						<Pagination
							activePage={activePage}
							itemsCountPerPage={sizePerPage}
							totalItemsCount={listTotal}
							pageRangeDisplayed={5}
							onChange={(event) => handlePageChange(event)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HistoryManager
