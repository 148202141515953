enum CHART_COLORS {
	RED = 'rgb(255, 177, 175)',
	BLUE = 'rgb(169, 184, 209)',
	YELLOW = 'rgb(244, 220, 124)',
	GREEN = 'rgb(171,204,204)',
	PURPLE = '#D7F9FA',
	ORANGE = 'rgb(221,157,100)',
	// RED = 'rgb(255, 177, 175)',
	// BLUE = '#3476D3',
	// YELLOW = '#EDC729',
	// GREEN = '#30CCCC',
	// PURPLE = '#D7F9FA',
	// ORANGE = '#F18D37',
	LABEL_TEXT = '#596275'
}

enum MOA_COLORS {
	BASIC = '#99d64f',
	BLACK_TEXT = '#424242',
	WHITE_TEXT = 'white',
	O_40 = 'rgb(200,223,178)'
}

enum NOTICE {
	LATEST_COUNT = 10,
	INTERVAL_TIME = 10000,
	PAGE_LIMIT = 10
}

const REFETCH_TIME = 30000
/**
 * 스터디모아 관리자 페이지 기본설명상수
 * @namespace {CONSTANTS} OBJ
 * @property {CHART_COLORS} 차트에 사용되는 색상모음
 * @property {REFETCH_TIME} 리액트 쿼리 재요청 시간 default = 30000
 * @property {MOA_COLORS} 스터디모아 기본색상모음
 * @property {NOTICE} 공지사항관련 ENUM
 */
const CONSTANTS = { CHART_COLORS, REFETCH_TIME, MOA_COLORS, NOTICE }
export default CONSTANTS
