import Metric from 'api/metric'
import { ChartOptions, ChartTypeRegistry } from 'chart.js'
import CONSTANTS from 'common/constant'
import CircleLoader from 'components/CircleLoader'
import { Doughnut } from 'react-chartjs-2'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import CardError from './CardError'
import CardWrapper from './CardWrapper'

interface ChartProps {
	options: ChartOptions<any>
	title?: string
	link?: string
	type?: keyof ChartTypeRegistry
	labels?: string[]
	bgs?: string[]
	disabledTotal?: boolean
	placeSeq: number
	[key: string]: any
}

const DeskUsageChart = ({
	options,
	title,
	link,
	placeSeq,
	labels = ['충전권', '지정석', '자유석', '실시간', '1회권'],
	bgs = [
		CONSTANTS.CHART_COLORS.RED,
		CONSTANTS.CHART_COLORS.BLUE,
		CONSTANTS.CHART_COLORS.YELLOW,
		CONSTANTS.CHART_COLORS.GREEN,
		CONSTANTS.CHART_COLORS.PURPLE
	]
}: ChartProps) => {
	const { isLoading, isError, refetch, data } = useQuery(['dashboard', 'total', 'desk', 'count'], () => Metric.getDeskCount(placeSeq))
	const navigate = useNavigate()
	if (isLoading || !placeSeq) {
		return (
			<CardWrapper>
				<CircleLoader />
			</CardWrapper>
		)
	}
	if (isError || !data?.result) {
		return <CardError refetch={refetch} />
	}
	const totalData = [
		data.result.charDeskCount,
		data.result.dayDeskCount,
		data.result.freeDeskCount,
		data.result.realDeskCount,
		data.result.timeDeskCount
	]
	const isData = totalData.reduce((acc, cur) => acc + cur) !== 0
	return (
		<CardWrapper className="flex p-4 bg-white shadow-sm border-2 rounded-md w-full 2md:w-fit min-w-[250px] min-h-[240px] max-h-[266px]">
			<div className="flex flex-col w-full 2md:w-fit">
				{title && (
					<span
						onClick={() => link && navigate(link)}
						className="w-fit cursor-pointer rounded-md hover:underline hover:text-white hover:bg-moa duration-300 font-bold text-xl whitespace-nowrap"
					>
						{title}
					</span>
				)}

				<div className="flex grow space-x-8">
					<div className="max-w-[30%] min-w-[30%] flex flex-col relative justify-center space-y-2">
						{labels?.map((label, idx) => (
							<div key={label} className="flex justify-between space-x-1">
								<div style={{ backgroundColor: bgs[idx] }} className="py-2 px-1"></div>
								<span className="whitespace-nowrap w-10">{label}</span>
								<div className="whitespace-nowrap ">
									<span className="font-bold" style={{ color: bgs[idx] }}>
										{totalData[idx]}
									</span>{' '}
									{/* / <span className="text-green-moa font-bold">{total}</span> */}
								</div>
							</div>
						))}
						<div className="flex justify-between items-center space-x-2">
							<div className="py-2 px-1" />
							<span className="whitespace-nowrap w-10">합계</span>
							<span className="whitespace-nowrap ">
								<span className="text-green-moa">{data.result.useDeskCount}</span>
							</span>
						</div>
					</div>

					<div className="relative right-[5%] max-w-[70%] mb:right-0">
						{!isData && (
							<span
								className="absolute top-[50%] left-[50%] text-[10px] whitespace-nowrap min-w-[250px] text-center"
								style={{ transform: 'translate(-50%, -50%)' }}
							>
								사용중인 좌석이 없습니다.
							</span>
						)}
						<Doughnut
							data={{
								labels,
								datasets: [
									{
										data: totalData,
										backgroundColor: bgs,
										hoverOffset: 1,
										borderWidth: 0
									}
								]
							}}
							options={{
								...options,
								cutout: '40%',
								offset: 1,
								responsive: true,
								maintainAspectRatio: false,
								// aspectRatio: 1,
								plugins: {
									datalabels: {
										formatter: (value, ctx) => {
											const datasets = ctx.chart.data.datasets
											if (!isData) {
												return null
											}
											if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												const sum = datasets[0].data.reduce((a, b) => +a! + +b!, 0)
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												const percent = Math.round((value / +sum!) * 100)
												if (!percent) {
													return null
												}
												const percentage = percent + '%'

												return percentage
											}
										},
										color: CONSTANTS.CHART_COLORS.LABEL_TEXT,
										clamp: true,
										font: {
											weight: 'bold'
										}
									},
									tooltip: {
										position: 'nearest',
										cornerRadius: 10,
										caretPadding: 10,
										caretSize: 10,
										yAlign: 'bottom'
									},
									legend: {
										display: false
									}
								}
							}}
						/>
					</div>
				</div>
			</div>
		</CardWrapper>
	)
}

export default DeskUsageChart
