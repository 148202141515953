import { motion, Variants } from 'framer-motion'
import { transition, whileTap } from './SunIcon'

interface MoonIconProps extends React.HTMLProps<SVGElement> {}
function MoonIcon({ className }: MoonIconProps) {
	const variants: Variants = {
		initial: { scale: 0.6, rotate: 90 },
		animate: { scale: 1, rotate: 0, color: 'rgb(253, 224, 70)', transition },
		whileTap,
		whileHover: {
			color: 'rgb(252, 194, 70)',
			scale: 1.2,
			rotate: 25
		}
	}

	return (
		<motion.svg
			className={`w-6 h-6 ${className}`}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			key="moon"
			initial="initial"
			animate="animate"
			whileTap="whileTap"
			whileHover="whileHover"
		>
			<motion.path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" fill="currentColor" variants={variants} />
		</motion.svg>
	)
}
export default MoonIcon
