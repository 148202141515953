import { iconStyle } from 'config'
import { motion, useAnimation, Variants } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavItem } from 'types/Navigation'

import ChildNav from './ChildNav'

export const variants: Variants = {
	initial: {
		transition: {
			// when: 'beforeChildren',
			// staggerChildren: 0.1
		}
	},
	start: {
		transition: {
			// when: 'beforeChildren',
			// staggerChildren: 0.1
		}
	}
}

const wrapperVariants: Variants = {
	initial: {
		originY: 0,
		height: 0,
		transition: {
			when: 'afterChildren',
			duration: 0.2,
			staggerChildren: 0.01
		}
	},
	start: {
		originY: 1,
		height: 'fit-content',
		transition: {
			when: 'beforeChildren',
			duration: 0.2,
			staggerChildren: 0.01
		}
	}
}

export interface SingleNavProps {
	item?: NavItem
	hover: boolean
}
const SingleNav = ({ item, hover }: SingleNavProps) => {
	const controls = useAnimation()
	const navigate = useNavigate()
	const location = useLocation()
	const path = location.pathname.slice(1, -1).split('/')
	const onPath = path[0] !== '' && item?.href?.includes(path[0])
	const ref = useRef<null | HTMLLIElement>(null)
	const stackRef = useRef<null | HTMLUListElement>(null)
	const [navOpen, setNavOpen] = useState(false)

	const initialAnimation = () => {
		controls.start('initial')
	}

	const startAnimation = () => {
		controls.start('start')
	}

	const navFocus = () => {
		const nav = document.getElementById('nav-container')
		if (hover) {
			if (!navOpen) {
				controls.start('start').then(() => stackRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }))
				if (nav) {
					nav.scrollBy({ top: ref.current?.offsetTop, behavior: 'smooth' })
				}
			} else {
				controls.start('initial')
			}
		}
	}
	useEffect(() => {
		if (!hover) {
			initialAnimation()
			setNavOpen(false)
		} else if (onPath) {
			startAnimation()
			navFocus()
			setNavOpen(true)
		}
		return () => setNavOpen(false)
	}, [hover])
	const handleClick = () => {
		ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		item?.href && navigate(item?.href)
	}
	return item?.children ? (
		<motion.ul ref={stackRef} className="group" variants={variants} animate={controls}>
			<div
				className={`cursor-pointer flex items-center space-x-4 rounded-md p-2 group-hover:bg-moa ${
					onPath && 'bg-moa'
				} duration-150 text-moaBlack`}
				onClick={() => {
					if (hover) {
						navFocus()
						setNavOpen(!navOpen)
					}
				}}
			>
				{item?.icon && (
					<item.icon
						className={`w-6 h-6 fill-moaBlack dark:fill-moaWhite group-hover:fill-white  ${
							onPath && 'fill-white dark:fill-moaWhite'
						} duration-150`}
						style={iconStyle}
					/>
				)}
				<div
					className={`flex relative justify-between w-full whitespace-nowrap dark:text-moaWhite group-hover:text-white ${
						onPath && 'text-white'
					} font-bold duration-150`}
				>
					<span className={`origin-left  duration-150 ${!hover && 'scale-0'} `}>{item?.title}</span>
					<span className={`absolute ${hover ? 'top-0 left-[calc(100%-8px)]' : '-left-5 -top-3'} duration-300`}>
						{navOpen ? ' -' : ' +'}
					</span>
				</div>
			</div>
			<motion.div className="origin-top soft-block space-y-1 pt-1 child-item-init" variants={wrapperVariants}>
				{item.children?.map((item, idx) => (
					<ChildNav key={idx} item={item} hover={hover} />
				))}
			</motion.div>
		</motion.ul>
	) : (
		<li
			ref={ref}
			onClick={handleClick}
			className={`cursor-pointer flex items-center space-x-4 rounded-md p-2 group hover:bg-moa ${
				onPath && 'bg-moa'
			} duration-300 text-moaBlack dark:text-moaWhite`}
		>
			{item?.icon && (
				<item.icon
					className={`w-6 h-6 fill-moaBlack group-hover:fill-white  dark:fill-moaWhite ${onPath && 'fill-white'}  duration-300`}
					style={iconStyle}
				/>
			)}
			<span
				className={`font-bold origin-left whitespace-nowrap  group-hover:text-white ${onPath && 'text-white'} duration-300 ${
					!hover && 'scale-0'
				}`}
			>
				{item?.title}
			</span>
		</li>
	)
}

export default SingleNav
