import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	login: false,
	isLoading: false,
	isNavOpen: false,
	text: '',
	isDark: localStorage.getItem('isDark') === 'true'
}

const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setLoginState: (state, { payload }) => {
			state.login = payload
		},
		setText: (state, { payload }) => {
			state.text = payload
		},
		setNavOpen: (state) => {
			state.isNavOpen = !state.isNavOpen
		},
		startLoading: (state) => {
			state.isLoading = true
		},
		endLoading: (state) => {
			state.isLoading = false
		},
		setIsDark: (state, { payload }) => {
			state.isDark = payload
			localStorage.setItem('isDark', payload + '')
		}
	}
})

export const { setLoginState, setText, startLoading, endLoading, setIsDark, setNavOpen } = commonSlice.actions
export default commonSlice.reducer
