import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import axios from '../../api/axios'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import TextField from '@material-ui/core/TextField'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import DetailDialog from './detailDialog'
import { isMobile } from 'react-device-detect'
import CardWrapper from 'common/components/CardWrapper'


const styles = (theme) => ({
	tableLayout: {
		background: 'white',
		border: 'solid 2px #dddddd',
		padding: '20px',
		margin: '10px',
		borderRadius: '12px'
	},
	messageLayout: {
		background: 'white',
		border: 'solid 2px #dddddd',
		padding: '20px',
		margin: '10px',
		borderRadius: '12px',
		width: '320px'
	},
	messageTitle: {
		display: 'block',
		textAlign: 'left',
		marginBottom: '10px',
		fontSize: '20px',
		fontWeight: '600'
	},
	messageTxt: {
		resize: 'none',
		marginTop: '20px',
		width: '100%'
	},
	messageBtn: {
		width: '100%',
		marginTop: '20px'
	}
})

function Notice({ classes }) {
	const [messageData, setMessageData] = useState([])
	const [messageValue, setMessageValue] = useState('')
	const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState(moment().add(1, 'month').format('YYYY-MM-DD'))
	const [defaultOrder, setDefaultOrder] = useState('seq')
	const [order, setOrder] = useState('desc')
	const [seq, setSeq] = useState(null)
	const [contents, setContents] = useState('')

	useEffect(() => {
		loadData()
	}, [])

	const loadData = async () => {
		axios
			.get('/notice/list', {
				params: {
					placeSeq: JSON.parse(localStorage.getItem('manager_place')).seq
				}
			})
			.then((res) => {
				const _messageData = res.data.list.map((el, i) => ({
					...el,
					no: res.data.list.length - i,
					wdate: moment(el.wdate).format('YYYY-MM-DD')
				}))
				setMessageData(_messageData)
			})
			.catch((error) => console.error(error))

		// axios
		// 	.get('/msg/types')
		// 	.then((res) => {
		// 		if (res && res.data && res.data.list) {
		// 			this.setState({ msgTemplates: res.data.list.map((el, i) => ({ ...el, no: i + 1 })) })
		// 		}
		// 	})
		// 	.catch((error) => console.error(error))
	}

	//Dialog Close Event
	const closeEvent = (data) => {
		if (!data) return
		else if (data === 'check') {
			alertMessage('경고', '공지내용을 입력해주세요.', 'danger')
			return
		} else if (data === 'edit') {
			alertMessage('알림', '수정 되었습니다', 'success')
		} else if (data === 'delete') {
			alertMessage('알림', '삭제 되었습니다', 'success')
		}
		loadData()
	}

	const messageSend = async () => {
		if (!startDate || !endDate) {
			alertMessage('알림', '공지 시작/종료일을 설정하세요', 'danger')
			return
		}
		if (messageValue === null || messageValue === '') {
			alertMessage('알림', '내용을 입력해주세요', 'danger')
			return
		}

		await axios
			.post('/notice/add', {
				placeSeq: JSON.parse(localStorage.getItem('manager_place')).seq,
				contents: messageValue,
				startDate: startDate,
				endDate: endDate
			})
			.then((res) => {
				if (res.data.result === 'success') {
					alertMessage('알림', '등록 되었습니다', 'success')
					loadData()
					reset()
				} else {
					alertMessage('알림', res.data.message, 'danger')
				}
			})
			.catch((error) => console.error(error))
	}

	const handleChange = (type, event) => {
		type === 'messageValue'
			? setMessageValue(event.target.value)
			: type === 'startDate'
			? setStartDate(event.target.value)
			: setEndDate(event.target.value)
	}

	const alertMessage = (title, message, type) => {
		Store.addNotification({
			title: title,
			message: message,
			type: type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: { duration: 3000 },
			dismissable: { click: true }
		})
	}

	const reset = () => {
		setMessageValue('')
	}

	const dataSort = async (dataField) => {
		if (defaultOrder !== dataField) {
			setDefaultOrder(dataField)
			setOrder('desc')
		} else {
			setDefaultOrder(dataField)
			if (order === 'desc') {
				setOrder('asc')
			} else {
				setOrder('desc')
			}
		}

		loadData()
	}

	const orderCheck = (value) => {
		if (value === defaultOrder) {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq'
						? 'No'
						: value === 'startDate'
						? '시작일'
						: value === 'endDate'
						? '종료일'
						: value === 'wdate'
						? '등록일'
						: value}
					<span className={classNames('order', order === 'asc' ? 'dropup' : '')}>
						<span className="caret" style={{ margin: '10px 5px' }}></span>
					</span>
				</div>
			)
		} else {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq'
						? 'No'
						: value === 'startDate'
						? '시작일'
						: value === 'endDate'
						? '종료일'
						: value === 'wdate'
						? '등록일'
						: value}
					<span className="order">
						<span className="dropdown">
							<span className="caret" style={{ margin: '10px 0px 10px 5px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
						<span className="dropup">
							<span className="caret" style={{ margin: '10px 0px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
					</span>
				</div>
			)
		}
	}

	const onRowClick = (row) => {
		document.getElementById('detailDialog_btn').click()
		setContents(row.contents)
		setSeq(row.seq)
	}

	return (
		<div style={{ padding: '5%', width: '100%' }}>
			<ReactNotifications />
			<div className={classNames('col-lg-8', 'col-md-12', 'col-sm-12', classes.tableLayout, 'bg-transparent')}>
				<BootstrapTable data={messageData} options={{ defaultSortName: 'seq', noDataText: '데이터 없음', onRowClick }} hover>
					<TableHeaderColumn dataField="seq" isKey={true} hidden={true} dataAlign="center">
						Seq
					</TableHeaderColumn>
					<TableHeaderColumn dataField="no" width="50px" dataAlign="center">
						{/* No */}
						{orderCheck('seq')}
					</TableHeaderColumn>
					<TableHeaderColumn dataField="startDate" width="80px" dataAlign="center">
						{/* 시작일 */}
						{orderCheck('startDate')}
					</TableHeaderColumn>
					<TableHeaderColumn dataField="endDate" width="80px" dataAlign="center">
						{/* 시작일 */}
						{orderCheck('endDate')}
					</TableHeaderColumn>
					<TableHeaderColumn dataField="contents" width="300px" dataAlign="left">
						공지내용
					</TableHeaderColumn>
					<TableHeaderColumn dataField="wdate" width="80px" dataAlign="center">
						{/* 등록일 */}
						{orderCheck('wdate')}
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
			<div className={classNames('col-lg-4', 'col-sm-12', classes.messageLayout, 'bg-transparent')}>
				<span className={classes.messageTitle}>공지등록</span>

				<textarea
					className={classNames('form-control', classes.messageTxt)}
					rows="8"
					maxLength="200"
					placeholder="공지내용을 입력해주세요(최대 200자)"
					value={messageValue}
					onChange={(e) => handleChange('messageValue', e)}
				></textarea>

				<div className={'row'} style={{ width: isMobile ? '100%' : '300px', margin: isMobile ? '0' : null }}>
					<div style={{ margin: '10px 15px 0px 15px' }}>
						공지시작일 :
						<TextField
							type="date"
							className={classes.textField}
							InputLabelProps={{
								shrink: true
							}}
							value={startDate}
							onChange={(e) => handleChange('startDate', e)}
						/>
					</div>
				</div>

				<div className={'row'} style={{ width: isMobile ? '100%' : '300px', margin: isMobile ? '0' : null }}>
					<div style={{ margin: '10px 15px 0px 15px' }}>
						공지종료일 :
						<TextField
							type="date"
							className={classes.textField}
							InputLabelProps={{
								shrink: true
							}}
							value={endDate}
							onChange={(e) => handleChange('endDate', e)}
						/>
					</div>
				</div>

				<Button variant="outlined" onClick={messageSend} color="primary" className={classes.messageBtn}>
					등록
				</Button>
			</div>

			<div className="hidden_"></div>
			<DetailDialog contents={contents} setContents={setContents} seq={seq} onClose={closeEvent} />
		</div>
	)
}

export default withStyles(styles)(Notice)
