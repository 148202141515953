import React from 'react'
import { NavItem } from 'types/Navigation'
import SingleNav from './SingleNav'

interface NavItemProps {
	items: NavItem[]
	hover: boolean
}

const ConstructNav = ({ items, hover }: NavItemProps) => {
	if (!items) {
		return null
	}

	return (
		<>
			{items.map((item, idx) => (
				<SingleNav key={idx} item={item} hover={hover} />
			))}
		</>
	)
}

export default ConstructNav
