const styles = (theme) => ({
	'comment-icon--selected': {
		color: theme.palette.primary.main
	},
	p: {
		color: 'red',
		background: 'white',
		border: 'solid 1px white',
		borderRadius: '50%',
		minWidth: '20px',
		minHeight: '20px',
		margin: '0px',
		fontSize: '12px',
		position: 'absolute',
		top: '2px',
		right: '4px',
		textAlign: 'center'
	}
})

export default styles
