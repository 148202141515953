import Metric from 'api/metric'
import CardWrapper from 'common/components/CardWrapper'
import LineChart from 'common/components/LineChart'
import withNavigation from 'common/withComponent/withNavigation'
import CircleLoader from 'components/CircleLoader'
import React from 'react'
import { useQuery } from 'react-query'
import { NavigateFunction } from 'react-router-dom'

const LineIncome = ({ placeSeq, navigate }: { placeSeq: number; navigate: NavigateFunction }) => {
	const { data, isLoading, error, refetch } = useQuery(['dashboard', 'line', 'income'], () => Metric.getLineIncomes(placeSeq))
	if (isLoading) {
		return (
			<CardWrapper className="moa-white-wrapper flex-col col-span-full space-y-2 max-h-fit">
				<CircleLoader />
			</CardWrapper>
		)
	}

	if (data) {
		return (
			<CardWrapper className="moa-white-wrapper flex-col col-span-full min-h-[300px] space-y-2 max-h-fit">
				<h1
					onClick={() => navigate('/sales')}
					className="cursor-pointer rounded-md hover:underline hover:text-white w-fit hover:bg-moa duration-300 font-bold text-xl whitespace-nowrap"
				>
					누적매출
				</h1>
				<div className="flex w-full h-full justify-between">
					<div className="h-full w-full relative">
						<LineChart incomes={data.result} />
					</div>
					{/* <IncomeTable incomes={result} /> */}
				</div>
			</CardWrapper>
		)
	}
	if (error || !data) {
		return (
			<CardWrapper className="flex p-4 shadow-sm border-2 rounded-md w-full min-h-[186px] min-w-[250px]">
				<div className="text-center flex justify-center items-center flex-col w-full space-y-2">
					<h3 className="text-lg font-bold text-moaBlack whitespace-nowrap">불러오기에 실패했습니다.</h3>
					<button
						className="ring-1 ring-moa px-2 py-1 rounded-md hover:bg-moa hover:ring-0 hover:text-white duration-300"
						onClick={() => refetch()}
					>
						재시도
					</button>
				</div>
			</CardWrapper>
		)
	}
	return null
}

export default withNavigation(LineIncome)
