import React from 'react'
import styled from 'styled-components'
const KeywordInput = ({ value, keyEvent }) => {
	return (
		<div>
			<div className="form-group-sm input-group input-group-sm">
				<InputKeyword
					className="form-control "
					type="text"
					defaultValue={value}
					// value={value}
					onChange={(event) => keyEvent(event)}
					placeholder="키워드명"
				/>
			</div>
		</div>
	)
}

export default KeywordInput
const InputKeyword = styled.input``
