import { ButtonHTMLAttributes } from 'react'
import { InsertButton } from 'react-bootstrap-table'

interface InsertBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	text?: string
}

const InsertBtn: React.FC<InsertBtnProps> = ({ onClick, text = '등록하기' }) => {
	return <InsertButton className="bg-[#5bc0de] focus:outline-none active:outline-none" btnText={text} onClick={onClick} />
}

export default InsertBtn
