import { ChartOptions, ChartTypeRegistry } from 'chart.js'
import CONSTANTS from 'common/constant'
import CircleLoader from 'components/CircleLoader'
import { useNavigate } from 'react-router-dom'
import CardWrapper from './CardWrapper'
import DoughnutChart from './DoughnutChart'
import StackedBar from './StackedBar'
interface ChartProps {
	data: any
	options: ChartOptions<any>
	title?: string
	link?: string
	loading: boolean
	error?: boolean
	type?: keyof ChartTypeRegistry
	labels?: string[]
	bgs?: string[]
	disabledTotal?: boolean
	refetch?: () => void
}

const Chart = ({
	data,
	options,
	title,
	loading,
	type = 'doughnut',
	link,
	error,
	disabledTotal = false,
	labels = ['사용', '미사용'],
	refetch,
	bgs = [CONSTANTS.MOA_COLORS.BASIC, CONSTANTS.MOA_COLORS.O_40]
}: ChartProps) => {
	if (loading) {
		return (
			<CardWrapper className="min-w-[200px]">
				<CircleLoader />
			</CardWrapper>
		)
	}

	if (error) {
		return (
			<CardWrapper className="flex p-4 shadow-sm border-2 rounded-md w-full min-h-[186px] min-w-[250px]">
				<div className="text-center flex justify-center items-center flex-col w-full space-y-2">
					<h3 className="text-lg font-bold text-moaBlack whitespace-nowrap">불러오기에 실패했습니다.</h3>
					<button
						className="ring-1 ring-moa px-2 py-1 rounded-md hover:bg-moa hover:ring-0 hover:text-white duration-300"
						onClick={() => refetch && refetch()}
					>
						재시도
					</button>
				</div>
			</CardWrapper>
		)
	}
	if (!data?.success || data?.total === 0) return null
	const navigate = useNavigate()
	return (
		<CardWrapper className="flex p-4 bg-white shadow-sm border-2 rounded-md w-full min-w-[250px] space-x-2 grow overflow-x-hidden">
			{!loading ? (
				error ? (
					<div className="text-center flex justify-center items-center flex-col w-full space-y-2">
						<h3 className="text-lg font-bold text-moaBlack whitespace-nowrap">불러오기에 실패했습니다.</h3>
						<button
							className="ring-1 ring-moa px-2 py-1 rounded-md hover:bg-moa hover:ring-0 hover:text-white duration-300"
							onClick={() => refetch && refetch()}
						>
							재시도
						</button>
					</div>
				) : (
					<>
						{!disabledTotal && (
							<div className="max-w-[30%] min-w-[30%] flex flex-col relative justify-center space-y-4">
								{title && (
									<span
										onClick={() => link && navigate(link)}
										className="cursor-pointer rounded-md hover:underline hover:text-white hover:bg-moa duration-300 absolute top-0 font-bold text-xl whitespace-nowrap"
									>
										{title}
									</span>
								)}
								{labels?.map((label, idx) => (
									<div key={label} className="flex justify-between items-center space-x-2">
										<div style={{ backgroundColor: bgs[idx] }} className="py-2 px-1"></div>
										<span className="whitespace-nowrap w-10">{label}</span>
										<span className="whitespace-nowrap ">
											<span className="font-bold" style={{ color: bgs[idx] }}>
												{idx ? data.total - data.used : data.used}
											</span>{' '}
											{/* / <span className="text-green-moa font-bold">{data.total}</span> */}
										</span>
									</div>
								))}
								<div className="flex justify-between items-center space-x-2">
									<div className="py-2 px-1" />
									<span className="whitespace-nowrap w-10">합계</span>
									<span className="whitespace-nowrap ">
										<span className="text-green-moa">{data.total}</span>
									</span>
								</div>
							</div>
						)}
						<div className="md:max-w-full max-w-[70%] mb:w-full flex items-center">
							{type === 'doughnut' && (
								<DoughnutChart data={data} bgs={bgs} labels={labels} options={options} percent={data.percent} />
							)}
							{type === 'bar' && (
								<>
									<StackedBar
										data={[
											{
												label: 'Employee',
												backgroundColor: '#caf270',
												data: [12, 59, 5, 56, 58, 12, 59, 87, 45]
											},
											{
												label: 'Engineer',
												backgroundColor: '#45c490',
												data: [12, 59, 5, 56, 58, 12, 59, 85, 23]
											},
											{
												label: 'Government',
												backgroundColor: '#008d93',
												data: [12, 59, 5, 56, 58, 12, 59, 65, 51]
											},
											{
												label: 'Political parties',
												backgroundColor: '#2e5468',
												data: [12, 59, 5, 56, 58, 12, 59, 12, 74]
											}
										]}
										options={options}
										percent={data.percent}
									/>
								</>
							)}
						</div>
					</>
				)
			) : (
				<CircleLoader />
			)}
		</CardWrapper>
	)
}

export default Chart
