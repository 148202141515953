import { iconStyle } from 'config'
import { motion, Variants } from 'framer-motion'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SingleNavProps } from './SingleNav'

const childVariants: Variants = {
	initial: {
		scaleY: 0,
		originY: 'top',
		transition: {
			// type: 'tween'
		}
	},
	start: {
		scaleY: 1,
		originY: 'top',
		transition: {
			// type: 'tween'
		}
	}
}

const ChildNav = ({ item, hover }: SingleNavProps) => {
	const navigate = useNavigate()
	const ref = useRef<null | HTMLLIElement>(null)
	const location = useLocation()
	const onPath = location.pathname.includes(item?.href ?? 'null')
	const [mouseEnter, setMouseEnter] = useState(false)
	const handleClick = () => {
		const nav = document.getElementById('nav-container')
		if (nav) {
			nav.scrollBy({ top: ref.current?.offsetTop, behavior: 'smooth' })
		}

		ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
		item?.href && navigate(item?.href)
	}
	return (
		<motion.li
			ref={ref}
			variants={childVariants}
			onClick={handleClick}
			onMouseEnter={() => setMouseEnter(true)}
			onMouseLeave={() => setMouseEnter(false)}
			className={`cursor-pointer soft-flex soft-scale justify-start items-center space-x-2 rounded-md p-1 px-2 ml-8 ${
				onPath && 'bg-moa'
			} ${mouseEnter && 'bg-moa'}  text-moaBlack duration-150`}
		>
			{item?.icon && (
				<item.icon
					className={`w-4 h-4 fill-moaBlack dark:fill-moaWhite duration-150 ${onPath && 'fill-white'} ${
						mouseEnter && 'fill-white'
					}`}
					style={iconStyle}
				/>
			)}
			<span
				className={`font-semibold dark:text-moaWhite text-xs origin-right whitespace-nowrap ${!hover && 'scale-0'} ${
					mouseEnter && 'text-white'
				} ${onPath && 'text-white duration-150'}`}
			>
				{item?.title}
			</span>
		</motion.li>
	)
}

export default ChildNav
