import { AppDispatch, RootState } from './store'
import { combineReducers } from 'redux'

import themeReducer from './reducers/theme.reducer'
import layoutReducer from './reducers/layout.reducer'
import roomReducer from 'reducers/room.reducer'
import commonReducer from 'reducers/common.reducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

// Combine with other reducers we may add in the future
const todoApp = combineReducers({
	// theme: themeReducer,
	// layout: layoutReducer,
	rooms: roomReducer,
	common: commonReducer
})

export default todoApp

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
