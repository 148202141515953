import { createGenerateClassName, createMuiTheme, jssPreset, MuiThemeProvider } from '@material-ui/core'
import { create } from 'jss'
import jssRTL from 'jss-rtl'
import React, { useEffect } from 'react'
import { JssProvider } from 'react-jss'
import { connect } from 'react-redux'
import { useAppSelector } from 'reducers'
import Routes from './routes'
const jss = create({ plugins: [...jssPreset().plugins, jssRTL()] })
const generateClassName = createGenerateClassName()

const App = (props) => {
	const isDarkState = useAppSelector((state) => state.common.isDark)
	// const { themeConfig, layoutConfig } = props
	// sessionStorage.setItem(
	// 	'portalData',
	// 	JSON.stringify({
	// 		theme: {
	// 			...themeConfig
	// 		},
	// 		layout: {
	// 			...layoutConfig
	// 		}
	// 	})
	// )

	useEffect(() => {
		if (isDarkState) {
			document.documentElement.classList.add('dark')
		} else {
			document.documentElement.classList.remove('dark')
		}
	}, [isDarkState])

	// const materialTheme = createMuiTheme(themeConfig.contentTheme)
	return (
		<JssProvider jss={jss} generateClassName={generateClassName}>
			{/* <MuiThemeProvider theme={materialTheme}> */}
			<Routes childProps={{}} />
			{/* </MuiThemeProvider> */}
		</JssProvider>
	)
}
// }

// function mapStateToProps(state) {
// 	return {
// 		themeConfig: state.theme,
// 		layoutConfig: state.layout
// 	}
// }
// export default connect(mapStateToProps)(App)
export default App
