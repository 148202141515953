import { AppBar, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const AdminNoticeRoute = () => {
	const [pageIndex, setPageIndex] = useState(0)

	const navigate = useNavigate()

	return (
		<div>
			<div>
				<AppBar position="static" color="default" className="bg-transparent">
					<Tabs
						value={pageIndex}
						onChange={(e, value) => {
							setPageIndex(value)
						}}
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab label="Home" onClick={() => navigate('')} />
						{/* <Tab label="List" onClick={() => navigate('list')} /> */}
					</Tabs>
				</AppBar>
			</div>

			<Outlet />
		</div>
	)
}

export default AdminNoticeRoute
