import { useRef } from 'react'
import CustomDialog from '../../wrapper/CustomDialog'
import { withStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { InsertButton } from 'react-bootstrap-table'

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
})

function detailDialog({ contents }) {
	const customDialog = useRef()

	const closeDialogReweighing = () => {
		customDialog.current.handleClose()
	}

	return (
		<CustomDialog
			title={'알림톡 상세'}
			className={'addDialog'}
			dialogButton={<InsertButton id="detailDialog_btn" btnText="상세보기" btnContextual="btn-warning" className="hidden_" />}
			innerRef={(ref) => (customDialog.current = ref)}
			maxWidth={'md'}
			aria-labelledby="event-dialog"
		>
			<DialogContent style={{ paddingTop: '0px', paddingBottom: '0px' }}>
				<div className={'row'} style={{ width: '500px' }}>
					<pre>{contents}</pre>
				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={closeDialogReweighing} color="default">
					닫기
				</Button>
			</DialogActions>
		</CustomDialog>
	)
}

export default withStyles(styles)(detailDialog)
