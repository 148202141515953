import withNavigation from 'common/withComponent/withNavigation'
// eslint-disable-next-line import/named
import { Outlet, NavigateFunction, Location } from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import { ReactNotifications } from 'react-notifications-component'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import jwt_decode from 'jwt-decode'
import adminApi from 'api/axios'

export interface withNavigationProps {
	navigate: NavigateFunction
	location: Location
}
const isDev = process.env.NODE_ENV === 'development'
interface CheckSeqRouterProps extends withNavigationProps {
	layout: (props: any) => JSX.Element
}

interface Manager {
	iat: number
	id: string
	permission: number
	placeSeq: number
	seq: number
	username: string
}

const CheckSeqRouter = ({ layout: Layout, location }: CheckSeqRouterProps) => {
	const removeStorage = () => {
		localStorage.removeItem('access_token')
		localStorage.removeItem('manager_place')
		sessionStorage.removeItem('manager_id')
		sessionStorage.removeItem('manager_seq')
		sessionStorage.removeItem('manager_permission')
	}
	if (localStorage.getItem('manager_place') === '' || localStorage.getItem('manager_place') === null) {
		if (window.location.pathname !== '/login') {
			window.location.pathname = '/login'
		}
	}

	// 반응형높이
	function setScreenSize() {
		if (isMobile && window.location.pathname.includes('seat')) {
			return
		}
		const vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	const checkToken = async () => {
		const token = localStorage.getItem('access_token')
		if (!token) {
			removeStorage()
			return
		}

		try {
			const checkToken = await adminApi.post<{ manager: Manager }>(
				'manager/check/token',
				{},
				{
					headers: {
						access_token: token
					}
				}
			)
			const { id, seq, permission } = checkToken.data.manager

			const dataset = {
				manager_id: id,
				manager_seq: seq,
				manager_permission: permission
			}

			sessionStorage.setItem('manager_id', dataset.manager_id)
			sessionStorage.setItem('manager_seq', dataset.manager_seq + '')
			sessionStorage.setItem('manager_permission', dataset.manager_permission + '')
		} catch (err: any) {
			if (err?.response?.status === 401) {
				removeStorage()
			}
			window.location.pathname = '/login'
		}
	}

	useEffect(() => {
		setScreenSize()
		checkToken()
		window.addEventListener('resize', setScreenSize)
		return () => window.removeEventListener('resize', setScreenSize)
	}, [location])
	return (
		<Layout>
			<ReactNotifications />
			<Outlet />
		</Layout>
	)
}

export default withNavigation(CheckSeqRouter)
