import React, { useRef, useState } from 'react'
import CustomDialog from '../../wrapper/CustomDialog'
import { withStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import axios from '../../api/axios'

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
})

function detailDialog({ seq, contents, setContents, onClose }) {
	const customDialog = useRef()

	const closeDialogReweighing = () => {
		customDialog.current.handleClose()
	}

	const handleSend = async () => {
		if (!contents) {
			onClose('check')
			return
		}

		await axios
			.post('/notice/edit', {
				seq: seq,
				placeSeq: JSON.parse(localStorage.getItem('manager_place')).seq,
				contents: contents
			})
			.then((res) => {
				if (res.data.result === 'success') {
					onClose('edit')
					closeDialogReweighing()
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const handleDelete = async () => {
		const result = window.confirm('해당 공지를 삭제하시겠습니까?')
		if (result) {
			await axios
				.post('/notice/edit', {
					seq: seq,
					placeSeq: JSON.parse(localStorage.getItem('manager_place')).seq,
					isLive: false
				})
				.then((res) => {
					if (res.data.result === 'success') {
						onClose('delete')
						closeDialogReweighing()
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}
	
	return (
		<div>
			<CustomDialog
				title={'공지내용'}
				className={'addDialog'}
				dialogButton={<InsertButton id="detailDialog_btn" btnText="상세보기" btnContextual="btn-warning" className="hidden_" />}
				innerRef={(ref) => (customDialog.current = ref)}
				maxWidth={'md'}
				aria-labelledby="event-dialog"
			>
				<DialogContent style={{ paddingTop: '0px', paddingBottom: '0px' }}>
					<div className={'row'} style={{ width: '500px' }}>
						<TextField
							placeholder="공지내용을 입력해주세요(최대200자)"
							style={{ width: '100%' }}
							className={'col-md-2'}
							margin="normal"
							type="text"
							multiline
							rows="8"
							maxLength="200"
							value={contents}
							onChange={(e) => setContents(e.target.value)}
						/>
						{/* <TextEditor placeholder="1234" value={this.state.contents} onChange={this.onChange} /> */}
					</div>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={handleDelete} color="secondary" style={{ float: 'left' }}>
						삭제
					</Button>
					<Button variant="outlined" onClick={closeDialogReweighing} color="default">
						닫기
					</Button>
					<Button variant="outlined" onClick={handleSend} color="primary" autoFocus>
						저장
					</Button>
				</DialogActions>
			</CustomDialog>
		</div>
	)
}

export default withStyles(styles)(detailDialog)
