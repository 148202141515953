import CONSTANTS from 'common/constant'
import moment from 'moment'
import { useMemo } from 'react'
import { Chart } from 'react-chartjs-2'
import { Income } from 'types/Income'

interface LineChartIncomeProps {
	incomes: Income[]
}
const LineChart = ({ incomes }: LineChartIncomeProps) => {
	const labels = [...incomes.map((income) => `${income.from}시`)]
	const totals = useMemo(
		() =>
			incomes
				.map((income) => (income.total ? +income.total : 0))
				.reduce<number[]>((acc, cur) => {
					const accNum = acc[acc.length - 1] ?? 0
					const sumNum = cur + accNum
					return [...acc, sumNum]
				}, [])
				.slice(0, moment().get('h') + 1),
		[incomes]
	)
	const totalIncome = totals.length && totals.reduce((acc, cur) => acc + cur)
	if (!totalIncome || !totals.length) {
		return (
			<span
				className="absolute top-[50%] left-[50%] text-[10px] whitespace-nowrap min-w-[250px] text-center"
				style={{ transform: 'translate(-50%, -50%)' }}
			>
				오늘 매출내역이 없습니다.
			</span>
		)
	}
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const
			},
			title: {
				display: true,
				text: 'Chart.js Line Chart'
			}
		}
	}

	return (
		<Chart
			type="bar"
			options={{
				...options,
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						stacked: true
					},
					y: {
						stacked: true
					}
				},
				plugins: {
					tooltip: {
						position: 'nearest',
						cornerRadius: 10,
						caretPadding: 10,
						caretSize: 10

						// rtl: true,
					},

					legend: {
						display: false,
						// align: 'end',
						fullSize: true,
						labels: {
							color: 'black',
							boxWidth: 100
						},
						position: 'right'
					}
				}
			}}
			data={{
				labels,
				datasets: [
					{
						label: '누적매출',
						data: totals,
						borderColor: CONSTANTS.MOA_COLORS.BASIC,
						backgroundColor: CONSTANTS.MOA_COLORS.O_40,
						hoverBorderColor: CONSTANTS.MOA_COLORS.O_40,
						hoverBorderWidth: 1,
						type: 'line',
						// barPercentage: 0.7,
						datalabels: {
							anchor: 'center',
							display(context) {
								if (context.dataIndex === context.dataset.data.length - 1) {
									return true
								}
								return false
							},
							align: 'start',
							offset: 0,
							clip: true,
							formatter: (value, ctx) => {
								return value.toLocaleString() + '원'
							},
							color: window.document.documentElement.classList.contains('dark')
								? CONSTANTS.MOA_COLORS.WHITE_TEXT
								: CONSTANTS.MOA_COLORS.BLACK_TEXT,
							clamp: false,
							font: {
								weight: 'bold',
								size: 14
							}
						}
					}
				]
			}}
		/>
	)
}

export default LineChart
