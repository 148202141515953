import adminApi from 'api/axios'
import { AdminApiResponse } from 'types/CommonType'
import { Income } from 'types/Income'
import type * as MetricTypes from 'types/metricType'
import type * as RoomTypes from 'types/Room'

async function getDesks(placeSeq: number): Promise<MetricTypes.Desks> {
	const res = await adminApi.get(`/metrics/dashboard/usages/desks/${placeSeq}`)
	return res.data
}
async function getLockers(placeSeq: number): Promise<MetricTypes.Lockers> {
	const res = await adminApi.get(`/metrics/dashboard/usages/lockers/${placeSeq}`)
	return res.data
}
async function getRooms(placeSeq: number): Promise<MetricTypes.Rooms> {
	const res = await adminApi.get(`/metrics/dashboard/usages/rooms/${placeSeq}`)
	return res.data
}
async function getSales(placeSeq: number): Promise<any> {
	const res = await adminApi.get(`/metrics/dashboard/usages/sales/${placeSeq}`)
	return res.data
}

async function getTotal(placeSeq: number): Promise<MetricTypes.MetricTotal> {
	const res = await adminApi.get(`/metrics/dashboard/${placeSeq}`)
	return res.data
}

async function getRoomReserved(placeSeq: number, roomKey: string): Promise<RoomTypes.RoomUsageResponse> {
	const res = await adminApi.get(`/metrics/dashboard/usages/today/room/${placeSeq}/${roomKey}`)
	return res.data
}

async function getLineIncomes(placeSeq: number): Promise<AdminApiResponse<Income[]>> {
	const res = await adminApi.get(`metrics/dashboard/sales/chart/${placeSeq}`)
	return res.data
}

async function getMonthSales(placeSeq: number): Promise<MetricTypes.TotalSales> {
	const res = await adminApi.get(`metrics/dashboard/sales/month/${placeSeq}`)
	return res.data
}

async function getDailySales(placeSeq: number): Promise<MetricTypes.TotalDailySales> {
	const res = await adminApi.get(`metrics/dashboard/sales/day/${placeSeq}`)
	return res.data
}

async function getOneDaySales(placeSeq: number): Promise<MetricTypes.OneDaySales> {
	const res = await adminApi.get(`metrics/dashboard/sales/one-day/${placeSeq}`)
	return res.data
}

async function getDeskCount(placeSeq: number): Promise<{ success: boolean; result: MetricTypes.getDeskCount }> {
	const res = await adminApi.get(`metrics/dashboard/desk/${placeSeq}`)
	return res.data
}

async function getRoomCount(placeSeq: number): Promise<{ success: boolean; result: MetricTypes.getRoomCount }> {
	const res = await adminApi.get(`metrics/dashboard/room/${placeSeq}`)
	return res.data
}
async function getLockerCount(placeSeq: number): Promise<{ success: boolean; result: MetricTypes.getLockerCount }> {
	const res = await adminApi.get(`metrics/dashboard/locker/${placeSeq}`)
	return res.data
}

const Metric = {
	getDesks,
	getLockers,
	getRooms,
	getSales,
	getTotal,
	getRoomReserved,
	getLineIncomes,
	getMonthSales,
	getDailySales,
	getOneDaySales,
	getDeskCount,
	getRoomCount,
	getLockerCount
}
export default Metric
