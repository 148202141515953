import React from 'react'
import CardWrapper from './CardWrapper'

interface CardErrorProps extends React.HTMLProps<HTMLDivElement> {
	refetch: () => void
}

const CardError = ({ refetch, className }: CardErrorProps) => {
	return (
		<CardWrapper className={`flex p-4 shadow-sm border-2 rounded-md w-full min-h-[186px] min-w-[250px] ${className}`}>
			<div className="text-center flex justify-center items-center flex-col w-full space-y-2">
				<h3 className="text-lg font-bold text-moaBlack whitespace-nowrap">불러오기에 실패했습니다.</h3>
				<button
					className="ring-1 ring-moa px-2 py-1 rounded-md hover:bg-moa hover:ring-0 hover:text-white duration-300"
					onClick={() => refetch && refetch()}
				>
					재시도
				</button>
			</div>
		</CardWrapper>
	)
}

export default CardError
