const styles = (theme) => ({
	'primary-icon': {
		color: theme.palette.primary.main
	},
	'secondary-icon': {
		color: theme.palette.secondary.main
	},
	dot: {
		backgroundColor: theme.palette.primary.contrastText
	}
})

export default styles
