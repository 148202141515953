import { AlignType, Board } from 'types/Board'
import axios from 'api/axios'
import CONSTANTS from 'common/constant'

export type BoardOrderBy = 'seq' | 'viewCount' | 'mdate'

interface MessageTitle {
	title: string
}

interface BoardReseponse<T = any> {
	success: boolean
	total_count: number
	total_page: number
	result?: T
}

interface ListResponse<T = any> extends BoardReseponse {
	list: T[]
}

async function getBoardList(
	orderBy: BoardOrderBy = 'seq',
	all: boolean | null = null,
	isAdmin: boolean | null = null,
	align: AlignType = 'desc'
) {
	return axios
		.get<BoardReseponse<Board[]>>('board/list', {
			params: {
				orderBy,
				align,
				all,
				isAdmin,
				page_limit: CONSTANTS.NOTICE.PAGE_LIMIT
			}
		})
		.then((res) => {
			return res.data
		})
}

async function getMessageList() {
	return axios.get<ListResponse<MessageTitle>>('/msg/types').then((res) => {
		if (res?.data?.list) {
			return { ...res.data, list: res.data.list.map((message, idx) => ({ ...message, no: idx + 1 })) }
		}
	})
}

async function removeBoard(seq: number) {
	return axios.delete('board/remove', { params: { seq } }).then((res) => res.data)
}

export const boardApi = { getBoardList, getMessageList, removeBoard }
