import { Button, Dialog, DialogActions, TextField, Typography, withStyles } from '@material-ui/core'
import { YYYYMMDD } from 'common/utils'
import styled from 'styled-components'
import { Board } from 'types/Board'

interface AdminDialogProps {
	data: Board | undefined
	open: boolean

	onCancel?: () => void
}

const AdminDialog = ({ data, open, onCancel }: AdminDialogProps) => {

	function autoLink(message: string | undefined) {
    const Rexp =
      /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/gi;
    if (message) {
      return message.replace(
        Rexp,
        "<a style='color:blue' href='$1' target='_blank'>$1</a>"
      );
    }
  }

	return (
		<Dialog aria-labelledby="customized-dialog-title" className="customDialog" open={open} onClose={onCancel}>
			<Main>
				<DialogTitle>
					<Typography variant="title">{data?.title}</Typography>
					<div style={{ height: '100%' }}>
						<WriteDate>{data && YYYYMMDD(data.wdate)}</WriteDate>
					</div>
				</DialogTitle>
				<DialogContent>
					<DialogChild
						className="ql-editor"
						style={{ overflowY: 'scroll', height: '100%' }}
						dangerouslySetInnerHTML={{
              __html: autoLink(data?.content) || "load",
            }}
					/>
				</DialogContent>
				<DateContainer>
					<Downloads>
						{[data?.imgUrl1, data?.imgUrl2].map(
							(img, idx) => img && <ImageDownload key={idx} href={img} download target="_blank" url={img}></ImageDownload>
						)}
					</Downloads>
				</DateContainer>
				<DialogActions>
					<Button variant="outlined" onClick={onCancel} color="default">
						닫기
					</Button>
				</DialogActions>
			</Main>
		</Dialog>
	)
}

export default withStyles({}, { withTheme: true })(AdminDialog)

const Main = styled.div`
	width: 100%;
	padding: 0.25rem;
`
const WriteDate = styled.div`
	font-size: 0.5rem;
	opacity: 0.7;
	white-space: nowrap;
	word-break: keep-all;
`
const DialogTitle = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`
const DialogContent = styled.div`
	margin: 0;
	position: relative;
	padding: 16px;
	padding-bottom: 0;
	min-width: 500px;
	flex: 1 1 auto;
	overflow-y: auto;
	& > div {
		height: 100%;
	}
	@media screen and (max-width: 425px) {
		min-width: 90vw;
	}
`
// const DialogChild = styled(TextField)`
// 	flex: 1 1 auto;
// 	padding: 0;
// 	padding: 0 24px 0;
// 	overflow-y: auto;
// 	width: 100%;
// `
const DialogChild = styled.div`
	flex: 1 1 auto;
	padding: 0;
	padding: 0 24px 0;
	overflow-y: auto;
	width: 100%;
	min-height: 20vh;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	margin-bottom: 5px;
	html {
		line-height: 1.15; /* 1 */
		-webkit-text-size-adjust: 100%; /* 2 */
	}
	body {
		margin: 0;
	}

	main {
		display: block;
	}

	h1 {
		font-size: 2em;
		margin: 0.67em 0;
	}

	hr {
		box-sizing: content-box; /* 1 */
		height: 0; /* 1 */
		overflow: visible; /* 2 */
	}

	pre {
		font-family: monospace, monospace; /* 1 */
		font-size: 1em; /* 2 */
	}

	a {
		background-color: transparent;
	}

	abbr[title] {
		border-bottom: none; /* 1 */
		text-decoration: underline; /* 2 */
		text-decoration: underline dotted; /* 2 */
	}

	& b,
	& strong {
		font-weight: bolder;
	}

	code,
	kbd,
	samp {
		font-family: monospace, monospace; /* 1 */
		font-size: 1em; /* 2 */
	}

	small {
		font-size: 80%;
	}

	sub,
	sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}

	sub {
		bottom: -0.25em;
	}

	sup {
		top: -0.5em;
	}

	img {
		border-style: none;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		font-family: inherit; /* 1 */
		font-size: 100%; /* 1 */
		line-height: 1.15; /* 1 */
		margin: 0; /* 2 */
	}

	button,
	input {
		/* 1 */
		overflow: visible;
	}

	button,
	select {
		/* 1 */
		text-transform: none;
	}

	button,
	[type='button'],
	[type='reset'],
	[type='submit'] {
		-webkit-appearance: button;
	}

	button::-moz-focus-inner,
	[type='button']::-moz-focus-inner,
	[type='reset']::-moz-focus-inner,
	[type='submit']::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}

	button:-moz-focusring,
	[type='button']:-moz-focusring,
	[type='reset']:-moz-focusring,
	[type='submit']:-moz-focusring {
		outline: 1px dotted ButtonText;
	}

	fieldset {
		padding: 0.35em 0.75em 0.625em;
	}

	legend {
		box-sizing: border-box; /* 1 */
		color: inherit; /* 2 */
		display: table; /* 1 */
		max-width: 100%; /* 1 */
		padding: 0; /* 3 */
		white-space: normal; /* 1 */
	}

	progress {
		vertical-align: baseline;
	}

	textarea {
		overflow: auto;
	}

	[type='checkbox'],
	[type='radio'] {
		box-sizing: border-box; /* 1 */
		padding: 0; /* 2 */
	}

	[type='number']::-webkit-inner-spin-button,
	[type='number']::-webkit-outer-spin-button {
		height: auto;
	}

	[type='search'] {
		-webkit-appearance: textfield; /* 1 */
		outline-offset: -2px; /* 2 */
	}

	[type='search']::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	::-webkit-file-upload-button {
		-webkit-appearance: button; /* 1 */
		font: inherit; /* 2 */
	}

	details {
		display: block;
	}

	summary {
		display: list-item;
	}

	template {
		display: none;
	}

	[hidden] {
		display: none;
	}

	& * {
		margin: 0;
	}
`

const DateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 24px;
`

const Downloads = styled.div`
	display: flex;
	white-space: nowrap;
	width: 100%;
	min-height: 80px;
`

export const ImageDownload = styled.a<{ url: string }>`
	background: url(${(props) => props.url});
	width: 80px;
	height: 100%;
	background-size: contain;
	margin-right: 1%;
	& img {
		object-fit: cover;
	}
`
