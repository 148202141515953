import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { alertMessage } from 'common/utils'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'
import axios from '../../api/axios'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { isMobile } from 'react-device-detect'
import scss from '../sales/sales.module.scss'


const CashStats = () => {
	const [options] = useState({ defaultSortName: 'seq', noDataText: '데이터 없음' })
	const [statsData, setStatsData]: any[] = useState()
	const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'))
	const [lastDate, setLastDate] = useState(moment().format('YYYY-MM-DD'))
	const loadData = async () => {
		if (!firstDate || !lastDate) {
			alertMessage({ title: '경고', message: '날짜를 선택해주세요.', type: 'danger' })
			return
		}

		try {
			const res = await axios.get('/cash/stats/place', {
				params: {
					from: firstDate,
					to: lastDate
				}
			})

			const total = res?.data.reduce(
				(acc, curr) => {
					acc.sum += +curr.sum
					acc.count += +curr.count
					return acc
				},
				{ sum: 0, count: 0 }
			)

			const tempData: any[] = []
			res.data.map((item) => {
				const data = {
					count: item.count,
					placeSeq: item.placeSeq,
					sum: item.sum,
					placeName: item.place.name
				}
				tempData.push(data)
			})

			tempData.push({ placeName: '합계', sum: total.sum, count: total.count })
			setStatsData(tempData)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		setTimeout(() => {
			setFirstDate(moment().format('YYYY-MM-DD'))
			setLastDate(moment().format('YYYY-MM-DD'))
			loadData()
		}, 200)
	}, [])

	return (
		<div
			style={{
				padding: '20px',
				minWidth: isMobile ? '100%' : 'auto',
				maxWidth: isMobile ? '600px' : '1400px',
				marginLeft: 'auto',
				marginRight: 'auto'
			}}
		>
			<ReactNotifications />

			<div className={classNames('row', scss.btn_layout)}>
				<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 sales_datesediv" style={{ padding: isMobile ? '0' : '' }}>
					<TextField
						id="date"
						type="date"
						style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
						className={'sales_dateselect'}
						InputLabelProps={{
							shrink: false
						}}
						value={firstDate}
						onChange={(e) => setFirstDate(e.target.value)}
					/>

					<span
						style={{
							width: isMobile ? '20px' : '30px',
							display: 'inline-block',
							textAlign: 'center',
							fontSize: isMobile ? '20px' : '30px',
							fontWeight: '400'
						}}
					>
						~
					</span>

					<TextField
						id="date"
						type="date"
						style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
						className={'sales_dateselect'}
						InputLabelProps={{
							shrink: true
						}}
						value={lastDate}
						onChange={(e) => setLastDate(e.target.value)}
					/>

					<Button
						variant="outlined"
						color="default"
						className={classNames('sales_datebtn', scss.searchBtn)}
						onClick={() => loadData()}
					>
						조회
					</Button>
				</div>
			</div>
			<div>
				<BootstrapTable data={statsData} options={options} hover trClassName={'study_place_bs'} condensed={true}>
					<TableHeaderColumn dataField="placeSeq" isKey={true} width="80px" dataAlign="center">
						Seq
					</TableHeaderColumn>
					<TableHeaderColumn dataField="placeName" width="140px" dataAlign="center">
						공간
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="sum"
						width="120px"
						dataFormat={(cell) => cell.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						dataAlign="center"
					>
						총액
					</TableHeaderColumn>
					<TableHeaderColumn dataField="count" width="120px" dataAlign="center">
						건수
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
		</div>
	)
}

export default CashStats
