import { Menu } from '@material-ui/core'
import { ReactEventHandler } from 'react'
import { NavList } from '../utils'
import ConstructNav from './ConstructNav'

interface ChangePlaceMenuProps {
	el?: null | HTMLElement | ((element: HTMLElement) => HTMLElement)
	data?: NavList[]
	// eslint-disable-next-line @typescript-eslint/ban-types
	onClose?: ReactEventHandler<{}>
	onClick?: (event: any, seq: any, key: any, name: any) => void
}

const ChangePlaceMenu = ({ el, data, onClose, onClick }: ChangePlaceMenuProps) => {
	return (
		<Menu
			// PaperProps={{
			// 	style: {
			// 		backgroundColor: 'white',
			// 		padding: '-10px 0'
			// 	}
			// }}
			id="admin-menu"
			anchorEl={el}
			open={Boolean(el)}
			onClose={onClose}
			className="scrollbar-hide"
			PaperProps={
				{
					// className: 'scrollbar-hide'
				}
			}
		>
			<div className="flex flex-col space-y-2 h-full bg-white dark:bg-moaDark p-2 focus:outline-none">
				{data?.map((item, idx) => (
					<div key={idx} onClick={(event) => onClick && onClick(event, item.seq, item.key, item.name)}>
						<ConstructNav hover={true} items={[{ title: item.name }]} />
					</div>
				))}
			</div>
		</Menu>
	)
}

export default ChangePlaceMenu
