import Metric from 'api/metric'
import CONSTANTS from 'common/constant'
import { getTotalUsageMinutes } from 'common/utils'
import CircleLoader from 'components/CircleLoader'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Room } from 'types/Room'
import StackedBar from './StackedBar'
import { motion, Variants } from 'framer-motion'
interface RoomUsageChartProps {
	room: Room
}

const DAY_TO_MINUTES = 1440

const RoomUsageChartVariants: Variants = {
	initial: {
		opacity: 0
	},
	start: {
		opacity: 1
	}
}
const RoomUsageChart = ({ room }: RoomUsageChartProps) => {
	const navigate = useNavigate()
	const { data, isLoading, error, refetch } = useQuery(['dashboard', 'room', room.placeSeq, room.key], () =>
		Metric.getRoomReserved(room.placeSeq, room.key)
	)
	const [loading, setLoading] = useState(isLoading)
	useEffect(() => {
		setLoading(isLoading)
	}, [loading])
	if (isLoading) {
		return (
			<div className="flex p-4 w-fit max-w-[40%]  mb:max-w-[150px] flex-col justify-between">
				<CircleLoader />
			</div>
		)
	}

	if (error) {
		return (
			<div className="flex p-4 w-fit flex-col justify-between">
				<h3 className="text-lg font-bold text-moaBlack whitespace-nowrap">불러오기에 실패했습니다.</h3>
				<button
					className="ring-1 ring-moa px-2 py-1 rounded-md hover:bg-moa hover:ring-0 hover:text-white duration-300"
					onClick={() => refetch()}
				>
					재시도
				</button>
			</div>
		)
	}

	if (data?.result) {
		const totalUsageMinutes = getTotalUsageMinutes(data.result) ?? 0
		const usageTime = totalUsageMinutes / DAY_TO_MINUTES
		const unUsageTime = (DAY_TO_MINUTES - totalUsageMinutes) / DAY_TO_MINUTES

		return (
			data?.result && (
				<motion.div className="flex p-4 w-fit max-w-[150px] flex-col justify-between" variants={RoomUsageChartVariants}>
					<h1
						className="cursor-pointer rounded-md hover:underline  duration-300 font-bold text-sm text-center"
						onClick={() => navigate('/room')}
					>
						{room.name}
					</h1>
					{!usageTime ? (
						<div className="h-full flex justify-center items-center px-6 text-[10px] whitespace-nowrap">
							<span>예약내역이 없습니다.</span>
						</div>
					) : (
						<div className="shrink-0">
							<StackedBar
								data={[
									{
										label: '점유',
										data: [Math.ceil(usageTime * 1000) / 10],
										backgroundColor: CONSTANTS.MOA_COLORS.BASIC
									},
									{
										label: '미점유',
										data: [Math.floor(unUsageTime * 1000) / 10],
										backgroundColor: CONSTANTS.MOA_COLORS.O_40
									}
								]}
								labels={['']}
							/>
						</div>
					)}
				</motion.div>
			)
		)
	}
	return null
}

export default RoomUsageChart
