import React from 'react'

// Material components
import Typography from '@material-ui/core/Typography'

const ContentFooter = () => {
	return (
		<Typography variant="title" color="inherit">
			<small className="text-[20%]">Copyright&copy; {new Date().getFullYear()} Mongddang Company all rights reserved.</small>
		</Typography>
	)
}

export default ContentFooter
