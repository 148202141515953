import { configureStore } from '@reduxjs/toolkit'

import roomReducer from './reducers/room.reducer'
import commonReducer from 'reducers/common.reducer'

const isDev = process.env.NODE_ENV === 'development' // 비활성화
export const store = configureStore({
	reducer: {
		// theme: themeReducer,
		// layout: layoutReducer,
		rooms: roomReducer,
		common: commonReducer
	},
	middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false })],
	devTools: isDev
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
