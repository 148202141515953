import withNavigation from 'common/withComponent/withNavigation'
import { ReactNotifications } from 'react-notifications-component'

import { Outlet } from 'react-router-dom'
import { withNavigationProps } from './CheckSeqRouter'

// interface AdminRouterProps extends withNavigationProps {}

const CheckAdminRouter = ({ navigate }: withNavigationProps) => {
	const isAdmin = sessionStorage.getItem('manager_seq') === '1'
	if (!isAdmin) {
		navigate('dashboard')
	}
	return (
		<>
			<ReactNotifications />
			<Outlet />
		</>
	)
}

export default withNavigation(CheckAdminRouter)
