import { useEffect, useState } from 'react'
import compose from 'recompose/compose'

// Material components
import IconButton from '@material-ui/core/IconButton'
import withTheme from '@material-ui/core/styles/withTheme'
import Toolbar from '@material-ui/core/Toolbar'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'

// Icons

import CreateIcon from '@material-ui/icons/Create'
import SystemIcon from '@material-ui/icons/Feedback'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import MenuIcon from '@material-ui/icons/Menu'
import ChatIcon from '@material-ui/icons/QuestionAnswer'
import Logoimg from 'assets/images/sm_symbol.png'

// Menu Items

// Themes

import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'reducers'
import axios from '../../../api/axios'
import withNavigation from 'common/withComponent/withNavigation'
import { setNavOpen } from 'reducers/common.reducer'

export function setTitle(items, currentPath) {
	for (let i = 0; i < items.length; i += 1) {
		if (currentPath === '/') {
			return '대시보드'
		} else if (items[i].href && items[i].href === currentPath) {
			return items[i].title === '매출내역'
				? `매출 > ${items[i].title}`
				: items[i].title === '수익정산'
				? `매출 > ${items[i].title}`
				: items[i].title === '월별 매출통계'
				? `통계/분석 > ${items[i].title}`
				: items[i].title === '월매출 분석'
				? `통계/분석 > ${items[i].title}`
				: items[i].title === '시간대별 이용현황'
				? `통계/분석 > ${items[i].title}`
				: items[i].title === '상품별 수익분석'
				? `통계/분석 > ${items[i].title}`
				: items[i].title
		} else if (currentPath.includes('admin/board')) {
			return '공지사항'
		}
		if (items[i].children) {
			const result = setTitle(items[i].children, currentPath)
			if (result) {
				return result
			}
		}
	}
	return null
}

function ContentToolbar(props) {
	const { width } = props
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const showBurgerMenu = isWidthDown('sm', width)

	const [state, setState] = useState({
		layoutMenuEl: null,
		layoutMenuOpen: false,
		themeMenuEl: null,
		themeMenuOpen: false,
		adminEl: null,
		systemCnt: '',
		userCnt: '',
		memberCnt: ''
	})
	const { adminEl } = state

	const checkCnt = async () => {
		if (parseInt(sessionStorage.getItem('manager_seq'), 10) !== 1) {
			axios
				.get(`/chat/manager/${sessionStorage.getItem('manager_seq')}/super/1`, {
					params: {
						placeSeq: (JSON.parse(localStorage.getItem('manager_place')) || {}).seq
					}
				})
				.then((res) => {
					if (res.status === 200) {
						let cnt = 0
						for (let i = 0; i < res.data.length; i++) {
							if (parseInt(res.data[i].newMsgCnt, 10) > 0) {
								cnt += 1
							}
						}
						setState((prev) => ({ ...prev, systemCnt: cnt }))
					}
				})
				.catch((error) => console.error(error))
			axios
				.get('/chat/manager/user', {
					params: {
						placeSeq: (JSON.parse(localStorage.getItem('manager_place')) || {}).seq
					}
				})
				.then((res) => {
					if (res.status === 200) {
						let cnt = 0
						for (let i = 0; i < res.data.length; i++) {
							if (parseInt(res.data[i].newMsgCnt, 10) > 0) {
								cnt += 1
							}
						}
						setState((prev) => ({ ...prev, memberCnt: cnt }))
					}
				})
				.catch((error) => console.error(error))
		} else {
			axios
				.get('/chat/super/manager')
				.then((res) => {
					if (res.status === 200) {
						let scnt = 0
						for (let i = 0; i < res.data.length; i++) {
							if (parseInt(res.data[i].newMsgCnt, 10) > 0) {
								scnt += 1
							}
						}
						setState((prev) => ({ ...prev, systemCnt: scnt }))
					}
				})
				.catch((error) => console.error(error))
			axios
				.get('/chat/super/user')
				.then((res) => {
					if (res.status === 200) {
						let scnt = 0
						for (let i = 0; i < res.data.length; i++) {
							if (parseInt(res.data[i].newMsgCnt, 10) > 0) {
								scnt += 1
							}
						}
						setState((prev) => ({ ...prev, userCnt: scnt }))
					}
				})
				.catch((error) => console.error(error))

			if (localStorage.getItem('manager_place')) {
				axios
					.get('/chat/manager/user', {
						params: {
							placeSeq: (JSON.parse(localStorage.getItem('manager_place')) || {}).seq
						}
					})
					.then((res) => {
						if (res.status === 200) {
							let cnt = 0
							for (let i = 0; i < res.data.length; i++) {
								if (parseInt(res.data[i].newMsgCnt, 10) > 0) {
									cnt += 1
								}
							}
							setState((prev) => ({ ...prev, memberCnt: cnt }))
						}
					})
					.catch((error) => console.error(error))
			}
		}
	}

	const handleChatClick = (url) => {
		props.navigate(url)
	}

	const name = JSON.parse(localStorage.getItem('manager_place'))?.name

	useEffect(() => {
		checkCnt()
	}, [])
	return (
		<Toolbar className="min-h-0 bg-moaWhite dark:bg-moaDark border-b-[1px] border-b-[#c5c5c5] dark:border-b-[rgba(255,255,255,0.1)] duration-300">
			<IconButton
				color="inherit"
				aria-label="open sidenav"
				style={{ display: showBurgerMenu ? 'block' : 'none' }}
				onClick={() => dispatch(setNavOpen())}
				className="dark:text-moaWhite"
			>
				<MenuIcon />
			</IconButton>
			<div className="flex space-x-2">
				<div
					className="w-8 h-8 ring-0 ring-moa ring-offset-0 hover:ring-2 hover:ring-offset-2 duration-300 rounded-full overflow-hidden cursor-pointer relative top-[3px]"
					onClick={() => navigate('/dashboard')}
				>
					<img className="relative -top-[3px]" src={Logoimg} alt="logo" />
				</div>
				<div className="flex flex-col w-fit justify-center items-center space-y-2 relative">
					<div
						className="flex text-sm whitespace-nowrap group-hover:text-[#424242] hover:underline duration-300 cursor-pointer relative"
						onClick={() => navigate('/editinfo')}
					>
						<CreateIcon className="absolute w-4 h-4 -right-4 -top-2 dark:fill-moaWhite" />
						{JSON.parse(localStorage.getItem('manager_place')) && (
							<span className="font-bold text-moaBlack dark:text-moaWhite duration-300">
								{showBurgerMenu ? (
									name.split(' ').map((item, idx) => {
										if (idx === name.split(' ').length - 1) {
											return <p key={idx}>{item}</p>
										} else {
											return <span key={idx}>{item}</span>
										}
									})
								) : (
									<span>{name}</span>
								)}
							</span>
						)}
					</div>
				</div>
			</div>

			<span className="portal-flex" />

			{/* 시스템 문의 메뉴 */}
			<IconButton
				id="toolbar_systemBtn"
				color="inherit"
				className="dark:text-moaWhite"
				aria-owns={adminEl ? 'admin-menu' : null}
				aria-haspopup="true"
				onClick={(e) => handleChatClick('/chat/manager')}
			>
				{state.systemCnt > 0 ? (
					<p
						style={{
							position: 'absolute',
							margin: '0px',
							top: '0',
							right: '0',
							color: 'white',
							background: 'red',
							minHeight: '20px',
							minWidth: '20px',
							textAlign: 'center',
							fontSize: '12px',
							borderRadius: '50%'
						}}
					>
						{state.systemCnt}
					</p>
				) : (
					''
				)}
				<SystemIcon />
				<div id="toolbar_system">시스템문의</div>
			</IconButton>
			{/* 시스템 문의 메뉴 */}
			{/* 1:1 문의 메뉴 */}
			{parseInt(sessionStorage.getItem('manager_seq')) === 9 ? (
				<IconButton
					id="toolbar_memberBtn"
					color="inherit"
					className="dark:text-white"
					aria-owns={adminEl ? 'admin-menu' : null}
					aria-haspopup="true"
					onClick={(e) => handleChatClick('/chat/user')}
				>
					{state.userCnt > 0 ? (
						<p
							style={{
								position: 'absolute',
								margin: '0px',
								top: '0',
								right: '0',
								color: 'white',
								background: 'red',
								minHeight: '20px',
								minWidth: '20px',
								textAlign: 'center',
								fontSize: '12px',
								borderRadius: '50%'
							}}
						>
							{state.userCnt}
						</p>
					) : (
						''
					)}
					<HeadsetMicIcon />
					<div id="toolbar_system">1:1문의</div>
				</IconButton>
			) : null}
			{/* 1:1 문의 메뉴 */}
			{/* 회원 문의 메뉴 */}
			{localStorage.getItem('manager_place') ? (
				<IconButton
					id="toolbar_memberBtn"
					color="inherit"
					className="dark:text-moaWhite"
					aria-owns={adminEl ? 'admin-menu' : null}
					aria-haspopup="true"
					onClick={(e) => handleChatClick('/chat/member')}
				>
					{state.memberCnt > 0 ? (
						<p
							style={{
								position: 'absolute',
								margin: '0px',
								top: '0',
								right: '0',
								color: 'moaWhite',
								background: 'red',
								minHeight: '20px',
								minWidth: '20px',
								textAlign: 'center',
								fontSize: '12px',
								borderRadius: '50%'
							}}
						>
							{state.memberCnt}
						</p>
					) : (
						''
					)}
					<ChatIcon />
					<div id="toolbar_member">회원문의</div>
				</IconButton>
			) : null}
		</Toolbar>
	)
}

export default compose(withWidth(), withTheme())(withNavigation(ContentToolbar))
