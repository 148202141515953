import { AdminApiResponse } from './../../../types/CommonType'
import { Board, ImageForm } from './../../../types/Board'
import { alertMessage, postImageUpload } from 'common/utils'
import { convertURLtoFile } from 'containers/studyroom/utils/roomUtils'
import adminApi from 'api/axios'
import { BoardFormValue } from '../AddDialog'

export function ImageFilesToBlobs(files: FileList | null, existFiles: (string | object)[] | null) {
	const filesArray = [] as any
	if (!files) return
	for (let i = 0; i < files.length; i++) {
		if (!files[i].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
			alertMessage({ title: '알림', message: '이미지 파일만 등록 가능합니다.', type: 'danger', duration: 3000 })
			// state.errors = { ...state.errors, fileError: { type: 'filesType', message: '이미지 파일만 등록 가능합니다.' } }
			return
		}
	}

	if (files.length > 2 || (existFiles && existFiles.length + files.length > 1)) {
		console.log('ON')
		alertMessage({ title: '이미지가 너무 많습니다.', message: '최대 1개 까지 등록 가능합니다.', type: 'danger', duration: 3000 })
		// state.errors = { ...state.errors, fileError: { type: 'filesLength', message: '최대 5개 까지 등록 가능합니다.' } }
		return
	}

	for (let i = 0; i < files.length; i++) {
		const url = URL.createObjectURL(files[i])
		const blobObj = {
			url,
			name: files[i].name,
			type: files[i].type
		}

		filesArray.push(blobObj)
	}
	return filesArray
}

export const boardImageParse = async (images: ImageForm[], placeKey = '999999') => {
	const fetchImages = images.map(async (image) => {
		const file = await convertURLtoFile(image)
		return postImageUpload(file, placeKey)
	})
	return await Promise.all(fetchImages).then((res) => {
		const result = res.map(({ data }) => data.url)

		return result
	})
}

export const postBoard = async (postData) => {
	return await adminApi.post<AdminApiResponse<Board>>('/board/create', postData).then((res) => res.data)
}

interface ImageObjs {
	imgUrl1?: string
	imgUrl2?: string
}
interface parseBoardResponse {
	success: boolean
	message: string
}

export async function parseBoardImages(
	images: ImageForm[] | null,
	data: BoardFormValue,
	existImages: object | null
): Promise<parseBoardResponse> {
	try {
		console.log(existImages, images)
		let imageUrls: ImageObjs | undefined
		if (images) {
			const result = await boardImageParse(images)
			let idx = 1
			imageUrls = result.reduce((acc, obj) => {
				const key = `imgUrl${idx}`
				if (obj[key]) {
					return { ...acc }
				}
				idx++
				return { ...acc, [key]: obj }
			}, {})
		}
		if (existImages && imageUrls) {
			const joinImage = [...Object.values(imageUrls), ...Object.values(existImages)]
			const newObj = joinImage.reduce((acc, cur, idx) => {
				return { ...acc, [`imgUrl${idx + 1}`]: cur }
			}, {})
			imageUrls = newObj
		}
		const postData = { ...data, ...imageUrls }
		console.log(postData)
		const res = await postBoard(postData)
		if (res.success) {
			return { success: true, message: '공지사항 등록이 완료되었습니다.' }
		}

		return { success: false, message: '저장에 실패햐였습니다. ' }
	} catch (err: any) {
		return { success: false, message: err.message }
	}
}
