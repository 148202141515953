import Metric from 'api/metric'
import CardError from 'common/components/CardError'
import CardWrapper from 'common/components/CardWrapper'
import CONSTANTS from 'common/constant'
import CircleLoader from 'components/CircleLoader'
import { useQuery } from 'react-query'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import CountUp from 'react-countup'
import { FcMoneyTransfer, FcCollaboration } from 'react-icons/fc'
import IncomeInfo from './IncomeInfo'
interface DailyTotal {
	placeSeq: number
}

const SimpleTotal = ({ placeSeq }: DailyTotal) => {
	const { data, isLoading, isError, refetch } = useQuery(['dashboard', 'sales', 'day'], () => Metric.getDailySales(placeSeq))

	if (isLoading) {
		return (
			<CardWrapper>
				<CircleLoader />
			</CardWrapper>
		)
	}

	if (isError || !data) {
		return <CardError refetch={refetch} />
	}

	const DetailVariants: Variants = {
		initial: {
			y: -10,
			opacity: 0
		},
		start: {
			y: 0,
			opacity: 1
		}
	}
	return (
		<CardWrapper key="showDetailWrapper" className="p-4 bg-white shadow-sm border-2 rounded-md w-full  flex flex-col">
			<div className="flex justify-between">
				<h1 className="font-bold text-xl">오늘의 현황</h1>
			</div>
			<div className="grid grid-cols-1 mb:space-y-0 gap-3 xl:grid-cols-2 my-auto">
				<motion.div
					key={'detailCard'}
					variants={DetailVariants}
					initial="initial"
					animate="start"
					exit="initial"
					className="grid grid-cols-[1fr_1fr_fit-content(50px)] gap-2 px-3"
				>
					<IncomeInfo title="좌석" sales={data.deskSales} count={data.deskSalesCount} />
					<IncomeInfo title="스터디룸" sales={data.roomSales} count={data.roomSalesCount} />
					<IncomeInfo title="락커" sales={data.lockerSales} count={data.lockerSalesCount} />
				</motion.div>
				<AnimatePresence exitBeforeEnter>
					<motion.div
						key={'totalCard'}
						variants={DetailVariants}
						initial="initial"
						animate="start"
						exit="initial"
						className="flex flex-col items-center place-content-center grow"
					>
						<div className="flex flex-col items-center space-y-4">
							<div className="flex">
								<div className="flex flex-col w-full">
									<div className="flex flex-col items-center">
										<FcMoneyTransfer className="w-8 h-8" />
										<span>매출</span>
									</div>

									<CountUp
										className="font-semibold text-sm text-center text-moa min-w-[100px] w-full"
										useGrouping
										useEasing
										separator=","
										suffix="<small>원</small>"
										duration={2}
										delay={0}
										preserveValue
										end={data.thisDaySales}
									/>
								</div>

								<div className="flex flex-col w-full">
									<div className="flex flex-col items-center">
										<FcCollaboration className="w-8 h-8" />
										<span>구매건수</span>
									</div>

									<CountUp
										className="font-semibold text-sm text-center text-blue-400 min-w-[100px] w-full"
										useEasing
										useGrouping
										separator=","
										suffix="<small>건</small>"
										duration={2}
										delay={0}
										preserveValue
										end={data.thisDaySalesCount}
									/>
								</div>
							</div>
						</div>
					</motion.div>
				</AnimatePresence>
			</div>
		</CardWrapper>
	)
}

export default SimpleTotal
