import React, { useState, useEffect, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'
import axios from '../../api/axios'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn, SearchField } from 'react-bootstrap-table'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Pagination from 'react-js-pagination'
import DetailDialog from './detailDialog'
import { isMobile } from 'react-device-detect'
import scss from '../sales/sales.module.scss'
import { debounce } from 'utils'


const PaymentList = () => {
	const [options] = useState({ defaultSortName: 'seq', noDataText: '데이터 없음' })
	const [paymentList, setPaymentList] = useState([])
	const [firstDate, setFirstDate] = useState(moment().date(1).format('YYYY-MM-DD'))
	const [lastDate, setLastDate] = useState(moment().add(1, 'months').date(0).format('YYYY-MM-DD'))
	const [detailData, setDetailData] = useState(null)
	const [activePage, setActivePage] = useState(1)
	const [listTotal, setListTotal] = useState(0)
	const [sizePerPage, setSizePerPage] = useState(10)
	const [searchValue, setSearchValue] = useState('')
	const [defaultOrder, setDefaultOrder] = useState('seq')
	const [order, setOrder] = useState('desc')

	// useEffect(() => {
	// 	loadData()
	// }, [])

	const loadData = useCallback(async () => {
		{
			if (firstDate === '' || firstDate === null || lastDate === '' || lastDate === null) {
				alertMessage({ title: '경고', message: '날짜를 선택해주세요.', type: 'danger' })
				return
			}
			// searchValue ? setActivePage(1) : null

			try {
				const { data: res } = await axios.get('/payment/list', {
					params: {
						from: firstDate,
						to: lastDate,
						page: activePage,
						perPage: sizePerPage,
						search: searchValue,
						orderBy: defaultOrder,
						align: order
					}
				})

				const paymentList =
					res.list &&
					res.list.map((el) => ({
						...el,
						userName: el.user ? el.user.name || '이름없음' : '',
						userPhone: el.user ? el.user.phone : ''
					}))
				setPaymentList(paymentList)
				setListTotal(res.total)
			} catch (e) {
				console.error(e)
			}
		}
	}, [activePage, sizePerPage, defaultOrder, order, searchValue, firstDate, lastDate])

	const changeSizePerPage = (n) => {
		// props.changeSizePerPage(n);
		setActivePage(1)
		setSizePerPage(n)
	}

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber)
	}

	useEffect(() => {
		loadData()
	}, [activePage, sizePerPage, defaultOrder, order, firstDate, lastDate])

	useEffect(() => {
		// setTimeout을 통해 delay 이후에 debouncedValue를 얻습니다.
		const timer = setTimeout(() => {
			loadData()
		}, 500)

		return () => {
			clearTimeout(timer) // 이 전의 timer를 clear합니다.
		}
	}, [searchValue])

	//Table 상세버튼
	const cellButton = (cell, row, enumObject, rowIndex) => {
		return (
			<div>
				<Button
					variant="outlined"
					color="default"
					className={'dark:text-moaWhite dark:border-moaWhite'}
					onClick={() => onDetail(cell, row, rowIndex)}
				>
					상세
				</Button>
				{row.receipt_url && (
					<Button
						variant="outlined"
						color="default"
						className={'dark:text-moaWhite dark:border-moaWhite'}
						onClick={() => window.open(row.receipt_url, '_blank')}
						style={{ marginLeft: '6px' }}
					>
						영수증
					</Button>
				)}
			</div>
		)
	}
	//상세 버튼 Click Event
	const onDetail = (cell, row, rowIndex) => {
		setDetailData(row)
		document.getElementById('detailDialog_btn').click()
	}

	//금액 Format
	const payFormat = (cell, row) => {
		let str = ''
		if (cell && cell.toString().indexOf('-') !== -1) {
			str = <p style={{ color: 'red' }}>{cell ? cell.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원' : '0원'}</p>
		} else {
			str = (
				<p style={{ color: 'black' }} className="dark:text-moaWhite">
					{cell ? cell.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원' : '0원'}
				</p>
			)
		}
		return str
	}

	const onKeyDown = (event) => {
		debounce(() => {
			if (event.key === 'Enter') {
				event.preventDefault()
				event.stopPropagation()
				onSearch()
			}
		}, 300)
	}

	const onSearch = async () => {
		loadData()
	}

	const dataSort = async (dataField) => {
		if (defaultOrder !== dataField) {
			setDefaultOrder(dataField)
			setOrder('desc')
		} else {
			setDefaultOrder(dataField)
			if (order === 'desc') {
				setOrder('asc')
			} else {
				setOrder('desc')
			}
		}

		loadData()
	}

	const orderCheck = (value) => {
		if (value === defaultOrder) {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq'
						? 'Seq'
						: value === 'userName'
						? '이름'
						: value === 'userPhone'
						? '전화번호'
						: value === 'status'
						? '상태'
						: value === 'pay_method'
						? '결제수단'
						: value === 'pg_provider'
						? '결제종류'
						: value === 'pay_service'
						? 'PG사'
						: value}
					<span className={classNames('order', order === 'asc' ? 'dropup' : '')}>
						<span className="caret" style={{ margin: '10px 5px' }}></span>
					</span>
				</div>
			)
		} else {
			return (
				<div onClick={() => dataSort(value)} style={{ cursor: 'pointer' }}>
					{value === 'seq'
						? 'Seq'
						: value === 'userName'
						? '이름'
						: value === 'userPhone'
						? '전화번호'
						: value === 'status'
						? '상태'
						: value === 'pay_method'
						? '결제수단'
						: value === 'pg_provider'
						? '결제종류'
						: value === 'pay_service'
						? 'PG사'
						: value}
					<span className="order">
						<span className="dropdown">
							<span className="caret" style={{ margin: '10px 0px 10px 5px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
						<span className="dropup">
							<span className="caret" style={{ margin: '10px 0px', color: 'rgb(204, 204, 204)' }}></span>
						</span>
					</span>
				</div>
			)
		}
	}

	useEffect(() => {
		setActivePage(1)
	}, [listTotal])

	return (
		<div
			style={{
				padding: '20px',
				minWidth: isMobile ? '100%' : null,
				maxWidth: isMobile ? '600px' : '1400px',
				marginLeft: 'auto',
				marginRight: 'auto'
			}}
		>
			<ReactNotifications />

			<div className={classNames('row', scss.btn_layout)}>
				<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 sales_datesediv" style={{ padding: isMobile ? '0' : null }}>
					<TextField
						id="date"
						type="date"
						style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
						className={'sales_dateselect'}
						InputLabelProps={{
							shrink: false
						}}
						value={firstDate}
						onChange={(e) => setFirstDate(e.target.value)}
					/>

					<span
						style={{
							width: isMobile ? '20px' : '30px',
							display: 'inline-block',
							textAlign: 'center',
							fontSize: isMobile ? '20px' : '30px',
							fontWeight: '400'
						}}
					>
						~
					</span>

					<TextField
						id="date"
						type="date"
						style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
						className={'sales_dateselect'}
						InputLabelProps={{
							shrink: true
						}}
						value={lastDate}
						onChange={(e) => setLastDate(e.target.value)}
					/>

					<Button
						variant="outlined"
						color="default"
						className={classNames('sales_datebtn', scss.searchBtn, 'dark:text-moaWhite dark:border-moaWhite')}
						onClick={() => loadData()}
					>
						조회
					</Button>
				</div>
			</div>
			<div>
				<div className="row">
					<div className="col-xs-0 col-sm-6 col-md-6 col-lg-9">
						<div className="btn-group btn-group-sm" role="group">
							<div>
								<div></div>
							</div>
						</div>
					</div>
					<div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
						<div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
							<input
								className="form-control "
								type="text"
								placeholder={isMobile ? '이름 & 전화번호' : '회원이름, '}
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
								onKeyDown={(event) => onKeyDown(event)}
								style={{ zIndex: '0' }}
							></input>
							<span className="input-group-btn">
								<button className="btn btn-default" onClick={() => onSearch()}>
									검색
								</button>
							</span>
						</div>
					</div>
				</div>
				<BootstrapTable
					data={paymentList}
					options={options}
					// search
					// keyBoardNav
					// pagination
					hover
					className={'study_place_bs'}
					condensed={true}
				>
					<TableHeaderColumn dataField="seq" isKey={true} width="50px" dataAlign="center">
						{orderCheck('seq')}
					</TableHeaderColumn>
					<TableHeaderColumn dataField="userName" width="70px" dataAlign="center">
						{orderCheck('userName')}
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="userPhone"
						width="90px"
						dataAlign="center"
						dataFormat={(cell) => <a href={'sms:' + cell}>{cell}</a>}
					>
						{orderCheck('userPhone')}
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="status"
						width="80px"
						dataFormat={(c) =>
							c === 'ready' ? '대기중' : c === 'paid' ? '결제완료' : c === 'cancelled' ? '환불' : c === 'failed' ? '실패' : c
						}
						dataAlign="center"
					>
						{orderCheck('status')}
					</TableHeaderColumn>
					<TableHeaderColumn dataField="pay_service" width="70px" dataAlign="center">
						{orderCheck('pay_service')}
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="pg_provider"
						dataFormat={(c) => (c != 'kakaopay' ? '일반결제' : '카카오페이')}
						width="70px"
						dataAlign="center"
					>
						{orderCheck('pg_provider')}
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="pay_method"
						width="80px"
						dataFormat={(c) =>
							c === 'card'
								? '카드'
								: c === 'vbank'
								? '가상계좌'
								: c === 'point'
								? '카카오머니'
								: c === 'trans'
								? '이체'
								: c === 'phone'
								? '휴대폰'
								: c === 'ssgpay'
								? 'SSG페이'
								: c === 'cardNum'
								? '비인증'
								: c
						}
						dataAlign="center"
					>
						{orderCheck('pay_method')}
					</TableHeaderColumn>
					<TableHeaderColumn dataField="payAmount" width="80px" dataFormat={payFormat} dataAlign="center">
						결제금액
					</TableHeaderColumn>
					<TableHeaderColumn dataField="cashAmount" width="80px" dataFormat={payFormat} dataAlign="center">
						캐시금액
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="card_name"
						width="90px"
						dataFormat={(c, r) => r.card_name || r.vbank_name || r.bank_name}
						dataAlign="center"
					>
						카드/은행
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="paid_at"
						width="90px"
						dataFormat={(c) => c && moment(c).format('M/D HH:mm:ss')}
						dataAlign="center"
					>
						완료일시
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="wdate"
						width="110px"
						dataFormat={(c) => moment(c).format('YY/M/D HH:mm:ss')}
						dataAlign="center"
					>
						결제시작일시
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="button"
						width="140px"
						dataAlign="left"
						dataFormat={(cell, row, enumObject, rowIndex) => cellButton(cell, row, enumObject, rowIndex)}
					></TableHeaderColumn>
				</BootstrapTable>
				<div className="btn-group" style={{ width: '100%' }}>
					{[10, 25, 50].map((n, idx) => {
						const isActive = n === sizePerPage ? 'active' : null
						return (
							<button
								key={idx}
								type="button"
								style={{ margin: isMobile ? '20px 0 0' : '20px 0' }}
								className={`btn ${isActive}`}
								onClick={() => changeSizePerPage(n)}
							>
								{n}
							</button>
						)
					})}
					<div style={{ float: 'right', width: isMobile ? '100%' : null, textAlign: isMobile ? 'right' : null }}>
						<Pagination
							activePage={activePage}
							itemsCountPerPage={sizePerPage}
							totalItemsCount={listTotal}
							pageRangeDisplayed={5}
							onChange={(event) => handlePageChange(event)}
						/>
					</div>
				</div>
			</div>
			<div className="hidden_">
				<DetailDialog detailData={detailData} />
			</div>
		</div>
	)
}

export default PaymentList
