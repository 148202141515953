import { useMemo } from 'react'
import { debounce } from 'utils'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as Quill from 'quill'
const toolbarOptions = [
	['link'],
	[{ header: [1, 2, 3, false] }],
	['bold', 'italic', 'underline', 'strike'],
	// ['blockquote'],
	// [{ list: 'ordered' }, { list: 'bullet' }],
	[{ color: [] }, { background: [] }],
	[{ align: [] }]
]
const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'align',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'background',
	'color',
	'link',
	// 'image',
	// 'video',
	'width'
]

interface TextEditorProps {
	value?: string | Quill.Delta
	placeholder?: string
	onChange?: (any) => void
	className?: string
}

const TextEditor = ({ value, placeholder, onChange, className, ...rest }: TextEditorProps) => {
	const modules = useMemo(
		() => ({
			toolbar: {
				container: toolbarOptions
			}
		}),
		[]
	)

	return (
		<ReactQuill
			{...rest}
			placeholder={placeholder}
			defaultValue={value || ''}
			className={className}
			onChange={(content, delta, source, editor) => {
				onChange && debounce(onChange(editor.getHTML()), 500)
			}}
			theme="snow"
			modules={modules}
			formats={formats}
		></ReactQuill>
	)
}

export default TextEditor
