import CardError from 'common/components/CardError'
import CONSTANTS from 'common/constant'
import { formatDiffNowTime } from 'common/utils'
import CircleLoader from 'components/CircleLoader'
import AdminDialog from 'containers/AdminNotice/AdminDialog'
import { boardApi } from 'containers/AdminNotice/utils/BoardApi'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React, { useState } from 'react'
import { CgCornerDownLeft } from 'react-icons/cg'
import { FcInfo } from 'react-icons/fc'
import { GoArrowRight } from 'react-icons/go'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { Board } from 'types/Board'
import Pagination from '@mui/material/Pagination'
import { PaginationItem } from '@mui/material'

const NoticeVariants: Variants = {
	initial: {
		transition: {
			staggerChildren: 0.03
		}
	},
	start: {
		transition: {
			staggerChildren: 0.03
		}
	}
}

const NoticeChildVariants: Variants = {
	initial: {
		opacity: 0
	},
	start: {
		opacity: 1
	}
}

interface NewNoticeProps {
	onClose?: () => void
}
const NewNotice = ({ onClose }: NewNoticeProps) => {
	const [page, setPage] = useState(0)
	const [open, setOpen] = useState<boolean>(false)
	const [selected, setSelected] = useState<Board | undefined>()
	const toggleOpen = (toggle: Board) => {
		setSelected(toggle)
		setOpen(true)
	}
	const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage((value - 1) * CONSTANTS.NOTICE.PAGE_LIMIT)
	}
	const {
		data: boardList,
		isLoading,
		isError,
		refetch
	} = useQuery(['user', 'board', 'all'], () => boardApi.getBoardList('mdate'), {
		cacheTime: Infinity,
		staleTime: Infinity,
		refetchOnWindowFocus: false
	})
	const boards = boardList?.result?.filter((board) => board.isPublished)
	if (isError) {
		return <CardError refetch={refetch} />
	}

	return (
		<div className="text-moaBlack dark:text-moaWhite duration-300">
			{isLoading ? (
				<CircleLoader />
			) : (
				<motion.div variants={NoticeVariants} exit="initial" animate="start" initial="initial" className="flex flex-col space-y-2">
					<div className="flex justify-between">
						<h1 className="font-bold text-xl">공지사항</h1>
						{onClose && <button onClick={onClose}>닫기</button>}
					</div>
					<AnimatePresence exitBeforeEnter>
						<div className="flex flex-col gap-3">
							{boards &&
								boards.slice(page, page + CONSTANTS.NOTICE.PAGE_LIMIT).map((item) => {
									return (
										<div className="flex gap-2 w-full space-x-2 justify-between" key={item.seq}>
											<WrapperMotion
												variants={NoticeChildVariants}
												className="grid grid-cols-[fit-content(24px)_1fr] items-center relative overflow-hidden w-full border-b px-2 shadow-sm  mb:my-0 group hover:bg-moa rounded-md cursor-pointer duration-300"
												key={item.seq + item.title}
												onClick={() => toggleOpen(item)}
											>
												<FcInfo className="info-icon w-3 h-3 duration-300 mr-2" />
												<span className="truncate group-hover:text-white">{item.title}</span>
												{/* <div className="text-base truncate w-full flex items-center space-x-2 group-hover:text-white"> */}
												{/* </div> */}
											</WrapperMotion>
											<div className="text-[8px] text-gray-400 flex items-end whitespace-nowrap">
												<span>{formatDiffNowTime(item.mdate)}</span>
											</div>
										</div>
									)
								})}
						</div>
					</AnimatePresence>
					{/* <button
						className="w-fit p-1 rounded-full scale-75 duration-300 hover:bg-moa group"
						onClick={() => {
							if (boardList?.total_count && boardList?.total_count <= page + CONSTANTS.NOTICE.PAGE_LIMIT) {
								setPage(0)
							} else {
								setPage(page + CONSTANTS.NOTICE.PAGE_LIMIT)
							}
						}}
					>
						{!boardList?.total_count ||
						boardList?.total_count === page ||
						boardList?.total_count < CONSTANTS.NOTICE.PAGE_LIMIT ? null : boardList?.total_count <
						  page + CONSTANTS.NOTICE.PAGE_LIMIT ? (
							<div className="flex items-center space-x-2 px-2 hover group-hover:text-white">
								<CgCornerDownLeft className="" />
								<span>첫페이지</span>
							</div>
						) : (
							<div className="flex items-center space-x-2 px-2 group-hover:text-white">
								<GoArrowRight className="" />
								<span>다음페이지</span>
							</div>
						)}
					</button> */}
					<Pagination
						count={boardList?.total_page}
						color="primary"
						size="small"
						siblingCount={1}
						showFirstButton
						showLastButton
						hidePrevButton
						hideNextButton
						variant="outlined"
						onChange={handlePagination}
						renderItem={(item) => <PaginationItem {...item} className="dark:text-moaWhite" />}
					/>
				</motion.div>
			)}
			{open && (
				<AdminDialog
					data={selected}
					open={open}
					onCancel={() => {
						setOpen(false)
						setSelected(undefined)
						// refetch()
					}}
				/>
			)}
		</div>
	)
}

export default NewNotice

const WrapperMotion = styled(motion.div)`
	& .info-icon circle:first-child {
		fill: #6baa1f;
		transition: fill 0.15s ease-in-out;
	}
	&:hover {
		.info-icon > circle:first-child {
			fill: #2196f3;
		}
	}
`
