import React from 'react'
import CustomDialog from '../../wrapper/CustomDialog'
import { withStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { InsertButton } from 'react-bootstrap-table'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { alertMessage } from 'common/utils'
import User from 'api/user'

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
})

class detailDialog extends React.Component {
	constructor(props) {
		super(props)
		this.customDialog = React.createRef()
		this.state = {
			stateDialogReweighing: false,

			seq: '', //Seq
			key: '', //key
			name: '', //이름
			phone: '', //전화번호
			memo: '', //메모
			wdate: '', //가입날짜
			gender: '', // 성별
			birthYear: '', //출생연도
			usage: '',
			cash: ''
		}
	}

	openDialogReweighing = () => {
		this.setState({ stateDialogReweighing: true })
		setTimeout(async () => {
			const userInfo = this.props.userInfo[0]

			try {
				const res = await User.getUserDetail(userInfo?.key, { incCash: true })
				this.setState({
					seq: res?.data?.seq,
					key: res?.data?.key,
					name: res?.data?.name,
					phone: res?.data?.phone,
					memo: res?.data?.memo,
					cash: res?.data?.userCash && res.data?.userCash.cash,
					wdate: res?.data?.wdate,
					gender: res?.data?.gender,
					birthYear: res?.data?.birthYear,
					passNum: res?.data?.passNum,
					usage: res?.data?.salesHistory
						? moment(res?.data?.salesHistory.saleDT).format('M/D HH:mm') +
						  (res?.data?.salesHistory.deskUsageSeq ? ` 좌석(${res.data.salesHistory.deskUsageSeq}) ` : '') +
						  (res?.data?.salesHistory.roomUsageSeq ? ` 스터디룸(${res.data.salesHistory.roomUsageSeq}) ` : '') +
						  (res?.data?.salesHistory.lockerUsageSeq ? ` 락커(${res.data.salesHistory.lockerUsageSeq}) ` : '') +
						  (res?.data?.salesHistory.amount ? res.data.salesHistory.amount.toLocaleString() + '원' : '')
						: '-'
				})
			} catch (error) {
				console.error(error)
			}
		}, 200)
	}

	closeDialogReweighing = () => {
		this.setState({ stateDialogReweighing: false })
		this.customDialog.handleClose()
	}

	deleteUser = async () => {
		try {
			const res = await User.deleteUser(this.state.seq)
			if (res?.data?.result === 'success') {
				this.props.onClose('delete')
				this.closeDialogReweighing()
			}
		} catch (error) {
			console.error(error)
		}
	}

	//저장
	handleSend = async () => {
		if ((this.state.passNum + '').length !== 6) {
			alertMessage({ title: '알림', message: '비밀번호는 6자리입니다.', type: 'info' })
			return
		}
		if (this.state.name === '' || this.state.name === null || this.state.phone === '' || this.state.phone === null) {
			this.props.onClose('check')
			return
		}
		const params = {
			seq: this.state.seq,
			key: this.state.key,
			name: this.state.name,
			phone: this.state.phone,
			gender: this.state.gender,
			birthYear: this.state.birthYear,
			memo: this.state.memo,
			passNum: this.state.passNum
		}
		try {
			const res = await User.updateUser(this.state.key, params)
			if (res?.data?.result === 'success') {
				this.props.onClose('edit')
				this.closeDialogReweighing()
			}
		} catch (error) {
			console.error(error)
		}
	}

	//메시지
	hrefMessage = () => {
		if (this.state.seq) {
			let data = {
				userSeq: this.state.seq,
				userName: this.state.name,
				userPhone: this.state.phone,
				newMsgCnt: 0,
				image: 'assets/images/avatars/admin.png'
			}
			localStorage.setItem('message_user', JSON.stringify(data))
			this.props.onClose('message')
		}
	}

	hrefSms = () => {
		window.location.href = `sms:${this.state.member_phone}`
	}

	mobileChk = () => {
		if (isMobile === false) {
			return 'none'
		}
	}

	//기본
	handleFormFieldChange = (prop, value) => {
		this.setState({ [prop]: value })
	}

	//기본
	handleChange = (name) => (event) => {
		this.setState({ [name]: event.target.value })
	}

	mobilePage = () => {
		return (
			<div className={'row'} style={{ width: '240px', margin: '0px' }}>
				<div className={'row'}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '120px', display: 'inline-block' }}>
						이름
					</span>
					<TextField
						placeholder=""
						style={{ width: '150px' }}
						className={'col-md-2'}
						margin="normal"
						type="text"
						value={this.state.name ? this.state.name : ''}
						onChange={(e) => this.handleFormFieldChange('name', e.target.value)}
					/>
				</div>

				<div className={'row'}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '10px', width: '120px', display: 'inline-block' }}>
						&thinsp;*전화번호
					</span>
					<TextField
						required
						placeholder="000-0000-0000"
						style={{ width: '150px' }}
						className={'col-md-2'}
						margin="normal"
						type="tel"
						value={this.state.phone ? this.state.phone : ''}
						onChange={(e) => this.handleFormFieldChange('phone', e.target.value)}
					/>
				</div>

				<div className={'row h-[56px]'}>
					<span className={'col-md-2'} style={{ textAlign: 'left', width: '120px', display: 'inline-block' }}>
						&thinsp; 성별
					</span>
					<select className='h-full mr-6 border-black border-b text-center' value={this.state.gender ? this.state.gender : ''} onChange={(e) => {this.handleFormFieldChange('gender',e.target.value)}}>
						<option value={''}>선택</option>
						<option value={'M'}>남</option>
						<option value={'W'}>여</option>
					</select>
				</div>

				<div className={'row'}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '120px', display: 'inline-block' }}>
						&thinsp; 출생연도
					</span>
					<TextField
						required
						placeholder="****"
						style={{ width: '150px' }}
						className={'col-md-2 number-arrow-disable'}
						margin="normal"
						type="number"
						inputProps={{
							maxLength: 4,
							className: 'number-arrow-disable'
						}}
						value={this.state.birthYear ? this.state.birthYear : ''}
						onChange={(e) => this.handleFormFieldChange('birthYear', e.target.value)}
					/>
				</div>

				<div className={'row'}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '120px', display: 'inline-block' }}>
						&thinsp; 비밀번호
					</span>
					<TextField
						required
						placeholder="******"
						style={{ width: '150px' }}
						className={'col-md-2'}
						margin="normal"
						type="password"
						inputProps={{
							maxLength: 6
						}}
						value={this.state.passNum ? this.state.passNum : ''}
						onChange={(e) => this.handleFormFieldChange('passNum', e.target.value)}
					/>
				</div>

				<div className={'row'}>
					<span className={'col-md-3'} style={{ textAlign: 'left', marginTop: '20px', width: '120px', display: 'inline-block' }}>
						최근 이용내역
					</span>
					<TextField
						disabled
						placeholder="없음"
						style={{ width: '150px' }}
						className={'col-md-9'}
						margin="normal"
						type="text"
						value={this.state.usage ? this.state.usage : ''}
					/>
				</div>

				<div className={'row'}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '120px', display: 'inline-block' }}>
						보유 캐시
					</span>
					<TextField
						disabled
						placeholder="없음"
						style={{ width: '150px' }}
						className={'col-md-2'}
						margin="normal"
						type="text"
						value={this.state.cash ? Number(this.state.cash).toLocaleString() + '원' : '0원'}
					/>
				</div>

				<div className={'row'}>
					<TextField
						placeholder="메모를 입력해주세요"
						style={{ width: '100%', marginLeft: '15px', marginRight: '15px' }}
						className={'col-md-2'}
						margin="normal"
						type="text"
						value={this.state.memo ? this.state.memo : ''}
						onChange={(e) => this.handleFormFieldChange('memo', e.target.value)}
					/>
				</div>

				<div className={'row'} style={{ width: '300px', marginTop: '10px', textAlign: 'right' }}>
					가입일 : {moment(this.state.wdate).format('YYYY/MM/DD HH:mm')}
				</div>
			</div>
		)
	}

	pcPage = () => {
		return (
			<div>
				<div className={'row'} style={{ width: '500px' }}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '80px' }}>
						이름
					</span>
					<TextField
						placeholder=""
						style={{ width: '110px' }}
						className={'col-md-2'}
						margin="normal"
						type="text"
						value={this.state.name ? this.state.name : ''}
						onChange={(e) => this.handleFormFieldChange('name', e.target.value)}
					/>

					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '100px' }}>
						&thinsp;*전화번호
					</span>
					<TextField
						required
						placeholder="000-0000-0000"
						style={{ width: '200px' }}
						className={'col-md-2'}
						margin="normal"
						type="text"
						value={this.state.phone ? this.state.phone : ''}
						onChange={(e) => this.handleFormFieldChange('phone', e.target.value)}
					/>
				</div>
				<div className={'row'}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '120px', display: 'inline-block' }}>
						&thinsp; 비밀번호
					</span>
					<TextField
						required
						placeholder="******"
						style={{ width: '150px' }}
						className={'col-md-2 number-arrow-disable'}
						margin="normal"
						type="password"
						inputProps={{
							maxLength: 6,
							className: 'number-arrow-disable'
						}}
						value={this.state.passNum ? this.state.passNum : ''}
						onChange={(e) => this.handleFormFieldChange('passNum', e.target.value)}
					/>
				</div>
				<div className='flex w-full'>
					<div className={'row flex items-center mt-3'}>
						<span className={'col-md-2'} style={{ textAlign: 'left', width: '60px', display: 'inline-block' }}>
							성별
						</span>
						<select className='h-full w-[105px] mr-6 border-b border-black text-center' value={this.state.gender ? this.state.gender : ''} onChange={(e) => {this.handleFormFieldChange('gender',e.target.value)}}>
							<option value={''}>선택</option>
							<option value={'M'}>남</option>
							<option value={'W'}>여</option>
						</select>
					</div>

					<div className={'row'}>
						<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '120px', display: 'inline-block' }}>
							&thinsp; 출생연도
						</span>
						<TextField
							required
							placeholder="****"
							style={{ width: '150px' }}
							className={'col-md-2 number-arrow-disable'}
							margin="normal"
							type="number"
							inputProps={{
								maxLength: 4,
								className: 'number-arrow-disable'
							}}
							value={this.state.birthYear ? this.state.birthYear : ''}
							onChange={(e) => this.handleFormFieldChange('birthYear', e.target.value)}
						/>
					</div>
				</div>

				<div className={'row'} style={{ width: '500px' }}>
					<span className={'col-md-3'} style={{ textAlign: 'left', marginTop: '20px' }}>
						최근 이용내역
					</span>
					<TextField
						disabled
						placeholder="없음"
						className={'col-md-9'}
						margin="normal"
						type="text"
						value={this.state.usage ? this.state.usage : ''}
					/>
				</div>

				<div className={'row'} style={{ width: '500px' }}>
					<span className={'col-md-2'} style={{ textAlign: 'left', marginTop: '20px', width: '120px' }}>
						보유 캐시
					</span>
					<TextField
						disabled
						placeholder="없음"
						style={{ width: '110px' }}
						className={'col-md-2'}
						margin="normal"
						type="text"
						value={this.state.cash ? Number(this.state.cash).toLocaleString() + '원' : '0원'}
					/>
				</div>

				<div className={'row'} style={{ width: '500px' }}>
					<TextField
						placeholder="메모를 입력해주세요"
						style={{ width: '470px', marginLeft: '15px', marginRight: '15px' }}
						className={'col-md-2'}
						margin="normal"
						type="text"
						value={this.state.memo ? this.state.memo : ''}
						onChange={(e) => this.handleFormFieldChange('memo', e.target.value)}
					/>
				</div>

				<div className={'row'} style={{ width: '500px', marginTop: '10px', textAlign: 'right' }}>
					가입일 : {moment(this.state.wdate).format('YYYY/MM/DD HH:mm')}
				</div>
			</div>
		)
	}

	renderPage = () => {
		return isMobile ? this.mobilePage() : this.pcPage()
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<CustomDialog
					title={'유저 상세'}
					className={'addDialog'}
					callbackFunction={this.openDialogReweighing}
					dialogButton={<InsertButton id="detailDialog_btn" btnText="수정" btnContextual="btn-warning" className="hidden_" />}
					innerRef={(ref) => (this.customDialog = ref)}
					maxWidth={'md'}
					aria-labelledby="event-dialog"
				>
					{/* <DialogTitle id="addEventDialog">{this.props.title}</DialogTitle> */}
					<DialogContent style={{ paddingTop: '0px', paddingBottom: '0px' }}>{this.renderPage()}</DialogContent>

					<DialogActions>
						<Button variant="outlined" style={{ margin: isMobile ? '0px' : null }} onClick={this.hrefMessage}>
							채팅
						</Button>
						<Button
							variant="outlined"
							onClick={this.hrefSms}
							style={{ display: this.mobileChk(), margin: isMobile ? '0px' : null }}
						>
							SMS
						</Button>
						<Button
							variant="outlined"
							onClick={this.deleteUser}
							color="secondary"
							style={{ margin: isMobile ? '0px' : null, marginRight: isMobile ? '0px' : '200px' }}
						>
							삭제
						</Button>
						<Button
							variant="outlined"
							style={{ margin: isMobile ? '0px' : null }}
							onClick={this.closeDialogReweighing}
							color="default"
						>
							닫기
						</Button>
						<Button
							variant="outlined"
							style={{ margin: isMobile ? '0px' : null }}
							onClick={this.handleSend}
							color="primary"
							autoFocus
						>
							저장
						</Button>
					</DialogActions>
				</CustomDialog>
			</div>
		)
	}
}

export default withStyles(styles)(detailDialog)
