import adminApi from 'api/axios'
import { RegisterUser, UpdateUser, UserType } from 'types/User'

async function registerUser(body: RegisterUser) {
	return await adminApi.post('/user/register', body)
}

async function getUserDetail(userKey: string, params: { incCash: boolean }) {
	return await adminApi.get(`/user/${userKey}`, { params })
}

async function deleteUser(seq: number) {
	return await adminApi.delete(`/v0/user/${seq}`)
}

async function updateUser(userKey: string, body: UpdateUser) {
	return await adminApi.put(`/v0/user/${userKey}`, body)
}

async function getUser(params: UserType) {
	return await adminApi.get('/user', { params })
}

const User = {
	registerUser,
	getUserDetail,
	deleteUser,
	updateUser,
	getUser
}

export default User
