import CardError from 'common/components/CardError'
import CardWrapper from 'common/components/CardWrapper'
import CircleLoader from 'components/CircleLoader'
import React from 'react'
import PersonIcon from '@material-ui/icons/Person'
import LockerIcon from '@material-ui/icons/ScreenLockLandscape'
import withNavigation from 'common/withComponent/withNavigation'
import { NavigateFunction } from 'react-router-dom'
import { useQuery } from 'react-query'
import Metric from 'api/metric'
interface LockerTotalProps {
	placeSeq: number
	navigate: NavigateFunction
}

const LockerTotal = ({ placeSeq, navigate }: LockerTotalProps) => {
	const { data, isError, isLoading, refetch } = useQuery(['dashboard', 'total', 'locker', 'count'], () => Metric.getLockerCount(placeSeq))
	if (isLoading) {
		return (
			<CardWrapper>
				<CircleLoader />
			</CardWrapper>
		)
	}
	if (isError || data?.result.lockerCount === undefined || data?.result.useLockerCount === undefined) {
		return <CardError refetch={refetch} />
	}

	return (
		<CardWrapper className="flex flex-col space-y-3 duration-300 cursor-pointer" onClick={() => navigate('/locker')}>
			<h1 className="font-bold text-xl">락커</h1>
			<aside className="grid grid-cols-[1.5fr_1fr] h-full font-semibold items-center duration-300 text-sm">
				<span className="whitespace-nowrap space-x-1 flex items-center">
					<LockerIcon />
					<span>사용중</span>
				</span>
				<span>
					{data.result.useLockerCount} / {data.result.lockerCount}
				</span>
			</aside>
		</CardWrapper>
	)
}

export default withNavigation(LockerTotal)
