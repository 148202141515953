// eslint-disable-next-line import/named
import {
	Button,
	Dialog,
	DialogActions,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	Switch,
	TextField,
	WithStyles,
	withStyles
} from '@material-ui/core'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import { purple } from '@material-ui/core/colors'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { ImageFilesToBlobs, parseBoardImages } from '../utils/utils'
import { alertMessage } from 'common/utils'
import { Board, ImageForm } from 'types/Board'
import TextEditor from 'components/TextEditor'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import { useAppDispatch, useAppSelector } from 'reducers'
import { endLoading, startLoading } from 'reducers/common.reducer'
import CircleLoader from 'components/CircleLoader'
import getImage from './getImage'

const styles = (theme) => {
	return {
		colorSwitchBase: {
			color: purple[300],
			'&$colorChecked': {
				color: '#8EC137',
				'& + $SliderBar': {
					backgroundColor: '#626262'
				}
			}
		},
		SliderBar: {
			transition: theme.transitions.create(['background-color'])
		},
		colorChecked: {
			color: '#8EC137'
		}
	}
}
interface AdminDialogProps extends WithStyles<typeof styles> {
	open: boolean
	onSave?: () => void
	onCancel: () => void
	onDelete?: (body: any) => void
	board?: Board
}

export interface BoardFormValue {
	title: string
	content: string
	linkUrl?: string
	startDt?: string
	endDt?: string
	isPublished: boolean
	isAlert?: boolean
	show: string
	imgUrl1?: string | null
}

const AddDialog = ({ open, onCancel, onDelete, classes, board }: AdminDialogProps) => {
	const dispatch = useAppDispatch()
	const isLoading = useAppSelector((state) => state.common.isLoading)
	const { control, reset, handleSubmit, watch, getValues } = useForm<BoardFormValue | Board>({
		defaultValues: {
			...board,
			startDt: board?.startDt && moment(board?.startDt).format('YYYY-MM-DD'),
			endDt: board?.endDt && moment(board?.endDt).format('YYYY-MM-DD')
		}
	})
	const [boardImageList, setBoardImageList] = useState<string[]>([])
	const boardImages = board?.imgUrl1
		? board.imgUrl2
			? [board.imgUrl1, board.imgUrl2]
			: board.imgUrl2
			? [board.imgUrl2]
			: [board.imgUrl1]
		: null
	useEffect(() => {
		if (!boardImages) return
		setBoardImageList(boardImages)
	}, [])

	const inputRef = useRef<HTMLInputElement>(null)
	const [images, setImage] = useState<ImageForm[] | null>(null)
	const [deleteImg, setDeleteImg] = useState('')
	const isPublished = useRef<boolean | string | null>(null)
	const isAlert = useRef<boolean | null | undefined>(null)
	isAlert.current = watch('isAlert')
	isPublished.current = watch('isPublished')
	const show = watch('show')
	const linkUrl = watch('linkUrl')
	const exitHandler = useCallback(() => {
		dispatch(endLoading())
		onCancel()
		reset()
	}, [open])
	const onChangeFiles = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files
		const existFiles =
			images && boardImageList ? [...images, ...boardImageList] : images ? images : boardImageList ? boardImageList : null
		const re = ImageFilesToBlobs(files, existFiles)
		if (re) {
			if (images) {
				setImage((prev) => [prev, re].flat())
				return
			}
			setImage([re].flat())
		}
	}
	const onValid = async (data: BoardFormValue) => {
		dispatch(startLoading())

		const dataContentCheck = data.content?.split('<p><br></p>').filter((v) => v).length === 0
		if (!data.title) {
			alertMessage({ title: '알림', message: '제목은 필수사항입니다.', type: 'warning' })
			dispatch(endLoading())
			return
		}
		if (isAlert.current) {
			if (!data.content || dataContentCheck) {
				alertMessage({ title: '알림', message: '내용은 필수사항입니다.', type: 'warning' })
				dispatch(endLoading())
				return
			}
		} else {
			if ((!data.content || dataContentCheck) && show !== 'USER') {
				alertMessage({ title: '알림', message: '내용은 필수사항입니다.', type: 'warning' })
				dispatch(endLoading())
				return
			}
			if (show === 'USER' && (!images || images.length === 0) && boardImageList.length === 0) {
				alertMessage({ title: '알림', message: '이미지는 필수사항입니다.', type: 'warning' })
				dispatch(endLoading())
				return
			}
		}

		const existImg = boardImageList
			? boardImageList.reduce((acc, cur, idx) => {
					return { ...acc, [`imgUrl${idx + 1}`]: cur }
			  }, {})
			: null

		const tmp = {
			...data,
			imgUrl1: boardImageList[0] || null
		}
		const res = await parseBoardImages(images, tmp, existImg)
		if (res.success) {
			exitHandler()
			alertMessage({ title: '알림', type: 'success', message: res.message })
		} else {
			alertMessage({ title: '알림', type: 'warning', message: res.message })
			dispatch(endLoading())
		}
	}

	const deleteImage = (e: { preventDefault: () => void }, index: number) => {
		e.preventDefault()
		if (boardImageList.length !== 0) {
			const tmp = boardImageList?.filter((_, idx) => idx !== index) || []
			setBoardImageList(tmp)
		} else {
			setImage([])
		}
	}

	useEffect(() => {
		setDeleteImg(getImage)
	}, [])

	return (
		<Dialog aria-labelledby="customized-dialog-title" className="customDialog" open={open} onClose={exitHandler}>
			{isLoading && (
				<div className="fixed top-0 left-0 bg-[rgba(0,0,0,0.3)] w-screen h-screen z-[10000]">
					<CircleLoader />
				</div>
			)}
			<Main onSubmit={handleSubmit(onValid)}>
				<DialogTitle>
					<Controller
						control={control}
						name="title"
						render={({ field }) => (
							<TextField
								placeholder="제목을 입력해주세요"
								fullWidth
								InputProps={{
									disableUnderline: true
								}}
								{...field}
							/>
						)}
					/>
				</DialogTitle>
				<DialogContent>
					<div className="row" style={{ borderBottom: '1px solid rgba(0,0,0, 0.12)' }}>
						<Controller
							control={control}
							name="content"
							render={({ field }) => <TextEditor {...field} className="row-md-2" placeholder="공지내용을 입력해주세요" />}
						/>
					</div>
				</DialogContent>
				<div className="p-4 pb-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div className="space-y-4">
						<Controller
							name="linkUrl"
							control={control}
							render={({ field }) => <TextField {...field} type="string" label="URL 링크 첨부" />}
						/>
						<div className="space-y-4">
							<Button
								color="default"
								variant="contained"
								onClick={() => {
									inputRef && inputRef.current?.click()
								}}
							>
								이미지 업로드
							</Button>
							<input
								ref={inputRef}
								type="file"
								hidden={true}
								onChange={(event) => onChangeFiles(event)}
								accept="image/*"
								multiple
								className="hidden"
							/>
						</div>

						<div className="flex space-y-2 flex-col mb:flex-row mb:space-x-2 mb:space-y-0">
							{boardImageList.length !== 0 &&
								boardImageList.map((image, idx) => (
									<ImageContainer control={control} target="_blank" url={image} key={image} href={linkUrl}>
										<DeleteButton
											onClick={(e: { preventDefault: () => void }) => {
												deleteImage(e, idx)
											}}
											src={deleteImg}
											alt=""
										/>
									</ImageContainer>
								))}
							{images &&
								images[0] &&
								images.map((image, idx) => (
									<ImageContainer control={control} target="_blank" key={idx} href={linkUrl} url={image.url}>
										<DeleteButton
											onClick={(e: { preventDefault: () => void }) => {
												deleteImage(e, idx)
											}}
											src={deleteImg}
											alt=""
										/>
									</ImageContainer>
								))}
						</div>
					</div>
					<BottomGroup className="last:justify-end">
						<DateContainer className="space-y-4 flex-col ">
							<div className="space-x-2">
								<Controller
									name="startDt"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											helperText={isMobile && !getValues().startDt ? '시작일 설정하기' : null}
											type="date"
											label="시작일"
											InputLabelProps={{ shrink: true }}
										/>
									)}
								/>

								<Controller
									name="endDt"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											helperText={isMobile && !getValues().endDt ? '종료일 설정하기' : null}
											type="date"
											label="종료일"
											InputLabelProps={{ shrink: true }}
										/>
									)}
								/>
							</div>
						</DateContainer>
						<div className="flex justify-between">
							<Controller
								control={control}
								name="isPublished"
								render={({ field }) => (
									<FormControlLabel
										control={
											<Switch
												classes={{
													bar: classes.SliderBar,
													switchBase: classes.colorSwitchBase,
													checked: classes.colorChecked
												}}
												checked={field.value}
												disableRipple
												{...field}
											/>
										}
										label={isPublished.current ? '공개' : '비공개'}
									/>
								)}
							/>
							<Controller
								control={control}
								name="isAlert"
								render={({ field }) => (
									<FormControlLabel
										control={
											<Switch
												classes={{
													bar: classes.SliderBar,
													switchBase: classes.colorSwitchBase,
													checked: classes.colorChecked
												}}
												checked={field.value}
												disableRipple
												{...field}
											/>
										}
										label={isAlert.current ? '긴급' : '일반'}
									/>
								)}
							/>
						</div>
						<div className="flex justify-between">
							<Controller
								name="show"
								control={control}
								defaultValue="ADMIN"
								render={({ field }) => (
									<RadioGroup {...field} className="flex flex-row">
										<FormControlLabel value="ADMIN" control={<Radio />} label="관리자" />
										<FormControlLabel value="USER" control={<Radio />} label="앱" />
									</RadioGroup>
								)}
							/>
						</div>
						<DialogActions>
							<Button variant="outlined" onClick={handleSubmit(onValid)} color="primary" autoFocus>
								저장
							</Button>
							<Button
								variant="outlined"
								onClick={() => onDelete && onDelete(board?.seq)}
								color="secondary"
								style={{ float: 'left' }}
							>
								삭제
							</Button>
							<Button variant="outlined" onClick={onCancel} color="default">
								닫기
							</Button>
						</DialogActions>
					</BottomGroup>
				</div>
			</Main>
		</Dialog>
	)
}

export default withStyles(styles, { withTheme: true })(AddDialog)

const Main = styled.form`
	width: 100%;
	padding: 0.25rem;
`
const BottomGroup = styled(FormGroup)`
	align-items: flex-end;
`
const DialogTitle = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`
const DialogContent = styled.div`
	margin: 0;
	position: relative;
	padding: 16px;
	padding-bottom: 0;
	min-width: 500px;
	flex: 1 1 auto;
	overflow-y: auto;
	& > div {
		height: 100%;
	}
	@media screen and (max-width: 425px) {
		min-width: 90vw;
	}
`

const DateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: 425px) {
		flex-direction: column;
		& div {
		}
	}
`

const ImageContainer = styled.a<{ url: string }>`
	background: url(${(props) => props.url});
	background-size: contain;
	width: 100px;
	height: 100px;
	position: relative;
`

const DeleteButton = styled.img`
	position: absolute;
	top: 5px;
	right: 5px;
	width: 20px;
	cursor: default;
`
