const styles = () => ({
	loaderWrapper: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '100%',
		height: '100%',
		placeContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		zIndex: 200
	}
})

export default styles
