/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */

import type { ChartOptions } from 'chart.js'
import CONTANTS from 'common/constant'
import { Doughnut } from 'react-chartjs-2'
import CountUp from 'react-countup'

interface DoughnutChartProps {
	data: any
	labels: string[]
	bgs: string[]
	percent?: number
	options?: ChartOptions<'doughnut'>
}

const DoughnutChart = ({ data, percent, bgs, labels, options }: DoughnutChartProps) => {
	const parsed = {
		labels,
		datasets: [
			{
				data: [data?.used, data?.total - data?.used],
				backgroundColor: bgs,
				hoverBackgroundColor: bgs,
				// borderWidth: 0,
				hoverOffset: 1,
				borderWidth: 0
			}
		]
	}
	return (
		<div className="relative">
			{/* <div className="relative"> */}
			<Doughnut
				data={parsed}
				options={{
					...options,
					responsive: true,
					maintainAspectRatio: false,
					// aspectRatio: 2,
					plugins: {
						tooltip: {
							position: 'nearest',
							cornerRadius: 10,
							caretPadding: 10,
							caretSize: 10,
							yAlign: 'bottom'
						},
						datalabels: {
							formatter: (value, ctx) => {
								const datasets = ctx.chart.data.datasets
								if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									const sum = datasets[0].data.reduce((a, b) => +a! + +b!, 0)
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									const percent = Math.round((value / +sum!) * 100)
									if (!percent) {
										return null
									}
									const percentage = percent + '%'
									return percentage
								}
							},
							color: CONTANTS.CHART_COLORS.LABEL_TEXT,
							clamp: true,
							font: {
								weight: 'bold'
							}
						},
						legend: {
							display: false
						}
					}
				}}
			/>
			{percent !== null && percent !== undefined && (
				<CountUp
					useGrouping
					useEasing
					decimalPlaces={2}
					suffix="%"
					decimals={2}
					separator=","
					duration={2}
					end={percent}
					preserveValue
					delay={0}
				>
					{({ countUpRef }) => (
						<div
							className="absolute top-[50%] left-[50%] text-[1.25em] font-semibold"
							style={{ transform: 'translate(-50%,-50%)' }}
						>
							<span ref={countUpRef} />
						</div>
					)}
				</CountUp>
			)}
			{/* </div> */}
		</div>
	)
}

export default DoughnutChart
