import { super_menuItems } from 'config'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AnimatePresence, motion, useAnimation, Variants } from 'framer-motion'
import { Dialog } from '@material-ui/core'
import NewNotice from 'containers/dashboard/components/Notice/NewNotice'
import { useQuery } from 'react-query'
import { boardApi } from 'containers/AdminNotice/utils/BoardApi'
import { IoMdMegaphone } from 'react-icons/io'
import { setMobileInterval } from 'common/utils'
import CONSTANTS from 'common/constant'
import { Board } from 'types/Board'
interface TitleHeaderProps {
	isTop: boolean
	isMobile: boolean
}

const HeaderVariants: Variants = {
	initial: {
		y: -100,
		width: 'fit-content',
		transition: {
			delay: 0.3
		}
	},
	start: {
		y: 0,
		transition: {
			delay: 0.3
		}
	},
	drawBorder: {
		width: '100%',
		transition: {
			duration: 3
		}
	}
}

const boderVariants: Variants = {
	initial: {
		y: 0,
		opacity: 1
	},
	hide: {
		y: -5,
		opacity: 0
	}
}
const BoardVariants: Variants = {
	initial: {
		y: 20,
		transition: {
			type: 'tween',
			duration: 0.8
		}
	},
	start: {
		y: 0,
		transition: {
			type: 'tween',
			duration: 0.8
		}
	},
	exit: {
		y: -20,
		transition: {
			type: 'tween',
			duration: 0.8
		}
	}
}
const TitleHeader = ({ isTop }: TitleHeaderProps) => {
	const {
		data: boardList,
		isLoading,
		isError,
		refetch
	} = useQuery(['user', 'board', 'all'], () => boardApi.getBoardList('mdate', false, true), {
		cacheTime: Infinity,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		refetchInterval: false
	})
	const controls = useAnimation()
	const progress = useAnimation()
	const location = useLocation()
	const setTitle = useCallback((items: typeof super_menuItems, currentPath: string) => {
		for (let i = 0; i < items.length; i += 1) {
			if (currentPath === '/') {
				return '대시보드'
			} else if (items[i].href && items[i].href === currentPath) {
				return items[i]?.title === '매출내역'
					? `매출 > ${items[i]?.title}`
					: items[i]?.title === '수익정산'
					? `매출 > ${items[i]?.title}`
					: items[i]?.title === '월별 매출통계'
					? `통계/분석 > ${items[i]?.title}`
					: items[i]?.title === '월매출 분석'
					? `통계/분석 > ${items[i]?.title}`
					: items[i]?.title === '시간대별 이용현황'
					? `통계/분석 > ${items[i]?.title}`
					: items[i]?.title === '상품별 수익분석'
					? `통계/분석 > ${items[i]?.title}`
					: items[i]?.title
			} else if (currentPath.includes('admin/board')) {
				return '공지사항'
			}
			if (items[i].children) {
				const result = setTitle(items[i].children, currentPath)
				if (result) {
					return result
				}
			}
		}
		return null
	}, [])
	useEffect(() => {
		controls.start('initial')
	}, [])

	useEffect(() => {
		if (isTop) {
			progress.start('initial')
		} else {
			progress.start('hide')
		}
	}, [isTop])

	const [boardIndex, setBoardIndex] = useState(0)
	useEffect(() => {
		let paging: NodeJS.Timer | null
		if (boardList?.total_count) {
			const maxPage = boardList?.total_count < CONSTANTS.NOTICE.LATEST_COUNT ? boardList?.total_count : CONSTANTS.NOTICE.LATEST_COUNT
			paging = setMobileInterval(() => {
				if (boardList) {
					if (boardIndex < maxPage - 1) {
						setBoardIndex((prev) => prev + 1)
					} else {
						setBoardIndex(0)
					}
				}
			}, CONSTANTS.NOTICE.INTERVAL_TIME)
		}
		return () => {
			paging && clearInterval(paging)
		}
	}, [boardIndex, boardList])

	const [showNotice, setShowNotice] = useState(false)
	return (
		<motion.div
			animate="start"
			variants={HeaderVariants}
			initial="initial"
			className={`text-sm w-full py-1 font-bold bg-white dark:bg-moaMainDark dark:text-moaWhite sticky top-0 mb:text-2xl z-10 ${
				isTop && 'shadow-sm'
			} flex justify-between items-center`}
		>
			{/* <span className="whitespace-nowrap px-4 border-b-white">{setTitle(super_menuItems, location.pathname)}</span> */}
			<motion.div
				className="absolute bottom-0 border-b-[1px] dark:border-b-[rgba(255,255,255,0.1)] w-full"
				variants={boderVariants}
				animate={progress}
			/>
			<div className="flex justify-between items-center w-full gap-1 mr-2 px-2">
				<IoMdMegaphone className="w-4 h-4" />
				<div className="overflow-hidden grow flex space-x-1 px-1 text-xs h-fit py-1 rounded-md   text-moaBlack dark:text-moaWhite duration-300">
					{boardList?.result && boardList?.result.length && (
						<AnimatePresence exitBeforeEnter>
							<motion.span
								className="truncate"
								key={boardIndex}
								variants={BoardVariants}
								initial="initial"
								animate="start"
								exit="exit"
							>
								{boardList?.result[boardIndex]?.title}
							</motion.span>
						</AnimatePresence>
					)}
				</div>
				<button
					onClick={() => setShowNotice(true)}
					className="text-xs hover:bg-moa hover:text-moaWhite duration-300 opacity-60 p-1 rounded-lg whitespace-nowrap"
				>
					더보기
				</button>
			</div>
			<Dialog
				classes={{
					paper: 'flex p-4 bg-white w-full dark:bg-moaDark border-2 dark:border-transparent rounded-md duration-300 border-none'
				}}
				open={showNotice}
				onClose={() => setShowNotice(false)}
			>
				<NewNotice onClose={() => setShowNotice(false)} />
			</Dialog>
		</motion.div>
	)
}

export default TitleHeader
