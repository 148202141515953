import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import { isMobile } from 'react-device-detect'
import {
	amountSalesFormat,
	loadSalesData,
	payMethodFormat,
	productSalesFormat,
	renderSizePerPageDropDown,
	salesDataFormat
} from './libs/parseData'
import * as CP from './TableWedget.styled'
import { useQuery } from 'react-query'
import CircleLoader from 'components/CircleLoader'
import CONSTANTS from 'common/constant'
import CardWrapper from 'common/components/CardWrapper'
import { useNavigate } from 'react-router-dom'


function TableWidgetSales() {
	const navigate = useNavigate()
	const [seq, _] = useState(JSON.parse(localStorage.getItem('manager_place'))?.seq || null)
	const { data, isLoading } = useQuery(['dashboard', 'tableWidgetSales'], () => loadSalesData(seq), {
		refetchInterval: (_, query) => (query.initialState.error ? 0 : CONSTANTS.REFETCH_TIME)
	})

	const options = {
		defaultSortName: 'no', // default sort column name
		defaultSortOrder: 'desc', // default sort order
		sizePerPageDropDown: renderSizePerPageDropDown,
		noDataText: '데이터 없음'
	}

	// useEffect(() => {
	// 	if (sessionStorage.getItem('manager_seq') !== '' && sessionStorage.getItem('manager_seq') !== null) {
	// 		load()
	// 	}
	// }, [])
	return (
		<CP.Wrapper id="dashboard_sales" className="w-full flex flex-col space-y-3">
			{isLoading ? (
				<CardWrapper className="min-h-[40vh] flex flex-col justify-center dark:text-moaWhite">
					<CircleLoader />
				</CardWrapper>
			) : (
				<>
					<div className="flex justify-between">
						<h1
							className="cursor-pointer w-fit rounded-md hover:underline hover:text-white hover:bg-moa duration-300 font-bold text-xl whitespace-nowrap dark:text-moaWhite"
							onClick={() => navigate('/sales')}
						>
							최근 매출내역
						</h1>
					</div>
					<CP.Tables isMobile={isMobile}>
						<BootstrapTable data={data} options={options} pagination hover className={'study_place_bs'}>
							<TableHeaderColumn dataField="no" isKey dataSort searchable={false} width="60px" dataAlign="center">
								No
							</TableHeaderColumn>
							<TableHeaderColumn width="160px" dataField="memberName" dataSort dataAlign="center">
								회원
							</TableHeaderColumn>
							<TableHeaderColumn
								width="160px"
								dataField="productName"
								dataFormat={productSalesFormat}
								dataSort
								dataAlign="center"
							>
								상품명
							</TableHeaderColumn>
							<TableHeaderColumn
								width="160px"
								dataField="amount"
								dataSort
								dataFormat={amountSalesFormat}
								searchable={false}
								dataAlign="center"
							>
								금액
							</TableHeaderColumn>
							<TableHeaderColumn
								width="160px"
								dataField="payMethod"
								dataSort
								dataFormat={payMethodFormat}
								searchable={false}
								dataAlign="center"
							>
								결제수단
							</TableHeaderColumn>
							<TableHeaderColumn
								width="160px"
								dataField="wdate"
								dataSort
								dataFormat={salesDataFormat}
								searchable={false}
								dataAlign="center"
							>
								등록시간
							</TableHeaderColumn>
						</BootstrapTable>
					</CP.Tables>
				</>
			)}
		</CP.Wrapper>
	)
}

export default TableWidgetSales
