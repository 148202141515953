import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'
import axios from '../../api/axios'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/css/react-bootstrap-table.css'

import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { isMobile } from 'react-device-detect'
import scss from '../sales/sales.module.scss'

const CashStats = () => {
	const [options] = useState({ noDataText: '데이터 없음' })
	const [statsData, setStatsData] = useState([])
	const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'))
	const [lastDate, setLastDate] = useState(moment().format('YYYY-MM-DD'))

	const loadData = async () => {
		if (!firstDate || !lastDate) {
			this.alertMessage('경고', '날짜를 선택해주세요.', 'danger')
			return
		}

		try {
			const res = await axios.get('/cash/stats/pg', {
				params: {
					from: firstDate,
					to: lastDate
				}
			})

			const statsData = res?.data || []

			const total = statsData.reduce(
				(acc, curr) => {
					acc.sum += +curr.sum
					acc.count += +curr.count
					return acc
				},
				{ sum: 0, count: 0 }
			)

			statsData.push({ pay_method: '합계', sum: total.sum, count: total.count })
			setStatsData(statsData)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		loadData()
	}, [])

	return (
		<div
			style={{
				padding: '20px',
				minWidth: isMobile ? '100%' : null,
				maxWidth: isMobile ? '600px' : '1400px',
				marginLeft: 'auto',
				marginRight: 'auto'
			}}
		>
			<ReactNotifications />

			<div className={classNames('row', scss.btn_layout)}>
				<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 sales_datesediv" style={{ padding: isMobile ? '0' : null }}>
					<TextField
						id="date"
						type="date"
						style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
						className={'sales_dateselect'}
						InputLabelProps={{
							shrink: false
						}}
						value={firstDate}
						onChange={(e) => setFirstDate(e.target.value)}
					/>

					<span
						style={{
							width: isMobile ? '20px' : '30px',
							display: 'inline-block',
							textAlign: 'center',
							fontSize: isMobile ? '20px' : '30px',
							fontWeight: '400'
						}}
					>
						~
					</span>

					<TextField
						id="date"
						type="date"
						style={{ display: 'inline-block', width: isMobile ? '120px' : '160px' }}
						className={'sales_dateselect'}
						InputLabelProps={{
							shrink: true
						}}
						value={lastDate}
						onChange={(e) => setLastDate(e.target.value)}
					/>

					<Button
						variant="outlined"
						color="default"
						className={classNames('sales_datebtn', scss.searchBtn, 'btn-dark')}
						onClick={() => loadData()}
					>
						조회
					</Button>
				</div>
			</div>
			<div>
				<BootstrapTable data={statsData} options={options} hover className={'study_place_bs'} condensed={true}>
					<TableHeaderColumn
						dataField="pay_service"
						dataFormat={(c) => (c === 'kakaopay' ? '카카오페이' : c === 'ksnet' ? 'KSNET' : c)}
						width="120px"
						dataAlign="center"
					>
						PG사
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="pay_method"
						isKey={true}
						dataFormat={(c, r) => {
							const meta = {
								card: '카드',
								point: '머니',
								vbank: '가상계좌',
								trans: '실시간이체',
								cardNum: '비인증',
								phone: '휴대폰',
								kakaopay: '카카오페이'
							}
							return `${r.pg_provider === 'kakaopay' ? '카카오페이 ' : ''}${meta[c] || '-'}`
						}}
						width="120px"
						dataAlign="center"
					>
						결제수단
					</TableHeaderColumn>
					<TableHeaderColumn
						dataField="sum"
						width="120px"
						dataFormat={(cell) => cell.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						dataAlign="center"
					>
						총액
					</TableHeaderColumn>
					<TableHeaderColumn dataField="count" width="120px" dataAlign="center">
						건수
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
		</div>
	)
}

export default CashStats
