import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const CircleLoader = ({ size = 50, text = 'Loading ...' }) => {
	return (
		<Wrapper>
			<CircularProgress size={size} />
			<span className="whitespace-nowrap">{text}</span>
		</Wrapper>
	)
}

export default CircleLoader
