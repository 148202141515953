import React from 'react'
interface CardWrapperProps extends React.HTMLProps<HTMLDivElement> {}
import { motion, Variants } from 'framer-motion'
export const CardVariants: Variants = {
	initial: {
		transition: {
			staggerChildren: 0.1
		}
	},
	start: {
		transition: {
			staggerChildren: 0.1
		}
	}
}
const CardWrapper: React.FC<CardWrapperProps> = ({ className, children, onClick }) => {
	return (
		<motion.div
			variants={CardVariants}
			initial="initial"
			animate="start"
			exit="start"
			className={`flex p-4 bg-white dark:bg-moaDark shadow-sm border-2 dark:border-transparent rounded-md duration-300 ${className}`}
			onClick={onClick}
		>
			{children}
		</motion.div>
	)
}

export default CardWrapper
